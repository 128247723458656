document.addEventListener('DOMContentLoaded', e => {
    let breadcrumb = document.querySelector('nav .breadcrumb');
    let breadcrumbLinks = breadcrumb && breadcrumb.querySelectorAll('.breadcrumb-item a');

    const makeLinksFocusable = (e) => {
        let code = e.charCode || e.keyCode;
        if (document.activeElement === breadcrumb && (code === 32 || code === 13)) {
            breadcrumbLinks.forEach(el => el.tabIndex = 0);
        }
    }

    document.addEventListener('keydown', (e) => makeLinksFocusable(e))
})