import {basePath, contextPath} from "./websiteConstants";
import debounce from "./debounce";



document.addEventListener('DOMContentLoaded', () => {

    const endpoint = basePath + contextPath + '/.rest/delivery/tags';
    const baseQueryString = '?orderBy=%40name%20asc&limit=100';
    const searchElements = document.querySelectorAll('.search-input input');
    let searchInput = '';
    const bracketsExp = new RegExp('\\(|\\)|\\[|\\]|\\{|\\}|\\<|\\>'); 
    const isMicrosite = document.body.classList.contains('microsite');

    if (!isMicrosite) {
        searchElements.forEach(
            el => {
                el.addEventListener('keydown', function(event) {    
                    if(bracketsExp.test(event.key)) {
                        event.preventDefault();
                    }
                }, false);

                el.addEventListener('keyup',
                    debounce((e) => {
                        searchInput = e.target.value;
                        const listElement = e.target
                            .closest('.col-12')
                            .nextElementSibling
                            .querySelector('.suggestions-list');
                        listElement.innerHTML = '';
                        if (searchInput.length > 1) {
                            fetchSearchSuggestions(searchInput, listElement);
                        }
                    })
                )
            }
        )
    }

    const fetchSearchSuggestions = (searchInput, listElement) => {
        const suggestionsQuery = '&%40name%5Blike%5D=' + searchInput.replaceAll(' ', '-') + '%25';
        const query = endpoint + baseQueryString + suggestionsQuery;
        fetch(query)
            .then(response => response.json())
            .then(data => {
                const results = data.results;
                results.forEach(result => {
                    const listItem = document.createElement('li');
                    const regex = new RegExp(`(${searchInput})`, 'ig')
                    const resultText = result['@name'].replaceAll('-', ' ')
                    const link = basePath + contextPath + '/suche?q=' + resultText;
                    listItem.innerHTML = `<a href="${link}" class="suggestion-link w-100 d-inline-block py-24 py-md-32">${resultText.replace(regex, '<strong>$1</strong>')}</a>`;
                    listElement.appendChild(listItem);
                })
            })
    }
})