function mgnlFormHandleBackButton(el) {
	var back = document.createElement('input');
	back.setAttribute('type', 'hidden');
	back.setAttribute('name', 'mgnlFormBackButtonPressed');
	//get the enclosing form. This is widely supported, even by IE4!
	el.form.appendChild(back);
	//and disable html5 form validation
	el.form.setAttribute('novalidate', 'novalidate');
	el.form.submit();
}

function removeClass(e, c) {
	e.className = e.className.replace(
		new RegExp('(?:^|\\s)' + c + '(?!\\S)'),
		''
	);
}

const cancelButtons = document.querySelectorAll(".form-cancel-button");
cancelButtons.forEach(cancelButton => {
	cancelButton.onclick = function() {
		return mgnlFormReset(this)
	}
});

const backButtons = document.querySelectorAll(".form-back-button");
backButtons.forEach(backButton => {
		backButton.onclick = function() {
		return mgnlFormHandleBackButton(this)
	}
});

// Reformat dates of form into dd.MM.yyyy
const forms = document.querySelectorAll("form");
forms.forEach(form => {
	form.onsubmit = function(e) {
		e.preventDefault();

		var dateInputs = document.querySelectorAll('input[type="date"]');
		dateInputs.forEach(function(input) {
			const id = input.id.replace("_unformatted", "");
			const hiddenInput = document.getElementById(id);
			if (input.value !== '') {
				var parts = input.value.split('-');
				var year = parts[0];
				var month = parts[1];
				var day = parts[2];
				var formattedDate = day + '.' + month + '.' + year;
				hiddenInput.value = formattedDate;
			}
		});

		var allInputs = document.querySelectorAll('input');
		allInputs.forEach(function(input) {
			if (input.value !== '' && input.type != "email") {
				input.value = input.value.replace(/((https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?)/gi, '');
			}
		});

		var textAreas = document.querySelectorAll('textarea');
		textAreas.forEach(function(input) {
			if (input.value !== '') {
				input.value = input.value.replace(/((https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?)/gi, '');
			}
		});
		
		form.submit();
		return;
	}
});

