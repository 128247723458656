document.addEventListener('DOMContentLoaded', e => {
    const body = nullSafeQuerySelector(document, 'body');

    // vars for mobile
    const mobileMenu = nullSafeQuerySelector(document,"#nav-mobile");
    const toggleMobileMenuButton = nullSafeQuerySelector(document,"#toggle-mobile-nav");
    const closeMobileMenuButtons = nullSafeQuerySelectorAll(document,".btn-close-mobile-nav");
    const level1Links = nullSafeQuerySelectorAll(mobileMenu,".level-1 > li > a");
    const listWrapper2 = nullSafeQuerySelector(mobileMenu,".list-wrapper:nth-child(2)");
    const listWrapper3 = nullSafeQuerySelector(mobileMenu,".list-wrapper:nth-child(3)");
    const subMenuWrapper2 = nullSafeQuerySelector(listWrapper2,".sub-menu-wrapper");
    const subMenuWrapper3 = nullSafeQuerySelector(listWrapper3,".sub-menu-wrapper");
    const backOneLevelBtns = nullSafeQuerySelectorAll(mobileMenu,".back-one-level");
    const isVisibleClass = "is-visible";
    const isActiveClass = "is-active";
    const menuString = !mobileMenu ? '' : mobileMenu.dataset.menuLabel;
    const languageSwitcherMobile = nullSafeQuerySelectorAll(document,'.languageSwitcherMobile');
    const loginLinkMobileNav = nullSafeQuerySelector(document,'.login-link-mobile-nav');
    const closeSearchOverlayMobileBtn = nullSafeQuerySelector(document,'.btn-close-main-nav-search-overlay-mobile');
    const toggleSearchOverlayMobileBtn = nullSafeQuerySelector(document,'#searchButtonNavMobile');
    const searchDropdownMobile = nullSafeQuerySelector(document,'#searchDropdownMobile');
    const mobileSearchInputField = nullSafeQuerySelector(searchDropdownMobile,"input");

    nullSafeAddEventListener(closeSearchOverlayMobileBtn,'click', function () {
        toggleSearchOverlayMobileBtn.setAttribute('aria-expanded', 'false');
        nullSafeClassList(toggleSearchOverlayMobileBtn).remove('show');
        nullSafeClassList(searchDropdownMobile).remove('show');
    });

    nullSafeAddEventListener(toggleSearchOverlayMobileBtn,'click', function () {
        mobileSearchInputField.focus();
    });

    nullSafeForEach(languageSwitcherMobile,function (switcher){
        nullSafeAddEventListener(switcher,"change", function (){
            changeLanguageOnMobile(this);
        });
    });

    nullSafeAddEventListener(toggleMobileMenuButton,"click", function () {
        toggleMobileNavVisibility(this);
    });

    nullSafeForEach(closeMobileMenuButtons, function (closeMobileMenuButton) {
        nullSafeAddEventListener(closeMobileMenuButton,"click", function () {
            toggleMobileNavVisibility(this);
        });
    });

    nullSafeForEach(level1Links, function (level1Link) {
        nullSafeAddEventListener(level1Link,"click", function (e) {
            buildNavLevelTwo(this, e);
        });
    });

    nullSafeAddEventListener(listWrapper2,"click", function (e) {
        buildNavLevelThree(e);
    });

    nullSafeForEach(backOneLevelBtns,function (backOneLevelBtn) {
        nullSafeAddEventListener(backOneLevelBtn,"click", function () {
            goBackOneLevel(this);
        });
    })

    nullSafeAddEventListener(closeSearchOverlayMobileBtn,'click', function () {
        toggleSearchOverlayMobileBtn.setAttribute('aria-expanded', 'false');
        nullSafeClassList(toggleSearchOverlayMobileBtn).remove('show');
        nullSafeClassList(searchDropdownMobile).remove('show');
    });

    nullSafeAddEventListener(toggleSearchOverlayMobileBtn,'click', function () {
        mobileSearchInputField.focus();
    });

    function setTabIndexOnAllSiblingsToInactive (el) {
        // if no parent, return no sibling
        if(!el.parentNode) {
            return;
        }

        // first child of the parent node
        let sibling  = el.parentNode.firstChild;

        // collecting siblings
        while (sibling) {
            if (sibling.nodeType === 1 && nullSafeQuerySelector(sibling,'a') !== null && sibling !== el) {
                nullSafeQuerySelector(sibling,'a').tabIndex = -1;
            }
            sibling = sibling.nextSibling;
        }
    }

    function setTabIndexOnAllChildrenToActive (listWrapper){
        nullSafeForEach(nullSafeQuerySelectorAll(listWrapper,'ul li'),function (listItem) {
            setTabIndexToActive(nullSafeQuerySelector(listItem,'a'));
        })
    }

    function setTabIndexToActive (el) {
        el.tabIndex = 0;
    }

    function buildNavLevelThree(clickedElement) {
        setTabIndexOnAllSiblingsToInactive(clickedElement.target.parentNode);
        const target = clickedElement.target;
        const isMicrosite = nullSafeClassList(document.body).contains("microsite");
        if (isMicrosite) {
            backOneLevelBtns[1].innerHTML = menuString;
        } else {
            backOneLevelBtns[1].innerHTML = menuString + " / " + target.innerHTML;
        }
        backOneLevelBtns[1].tabIndex = 0;
        backOneLevelBtns[0].tabIndex = -1;
        closeMobileMenuButtons[1].tabIndex = -1;
        closeMobileMenuButtons[2].tabIndex = 0;
        if (target.tagName.toLowerCase() === "a" && target.nextElementSibling) {
            const siblingList = target.nextElementSibling;
            clickedElement.preventDefault();
            nullSafeClassList(target).add(isActiveClass);
            const cloneSiblingList = siblingList.cloneNode(true);
            subMenuWrapper3.innerHTML = "";
            subMenuWrapper3.append(cloneSiblingList);
            nullSafeClassList(listWrapper3).add(isVisibleClass);
        }
    }

    function buildNavLevelTwo(level1Link, clickedElement) {
        // Set Sibling Before or after, tabindex and login to tabindex 0
        setTabIndexOnAllSiblingsToInactive(level1Link.parentNode);
        languageSwitcherMobile[0].tabIndex = -1;
        if (loginLinkMobileNav) {
            loginLinkMobileNav.tabIndex = -1;
        }
        closeMobileMenuButtons[1].tabIndex = 0;
        closeMobileMenuButtons[2].tabIndex = -1;
        const siblingList = level1Link.nextElementSibling;
        const isMicrosite = nullSafeClassList(document.body).contains("microsite");
        if (isMicrosite) {
            backOneLevelBtns[0].innerHTML = menuString;
        } else {
            backOneLevelBtns[0].innerHTML = menuString + " / " + level1Link.innerHTML;
        }
        backOneLevelBtns[0].tabIndex = 0;
        backOneLevelBtns[1].tabIndex = -1;
        if (siblingList) {
            clickedElement.preventDefault();
            nullSafeClassList(level1Link).add(isActiveClass);
            const cloneSiblingList = siblingList.cloneNode(true);
            subMenuWrapper2.innerHTML = "";
            subMenuWrapper2.append(cloneSiblingList);
            nullSafeClassList(listWrapper2).add(isVisibleClass);
        }
    }

    function toggleMobileNavVisibility(el) {
        nullSafeClassList(mobileMenu).toggle(isVisibleClass);
        nullSafeClassList(body).toggle('overflow-hidden');
        nullSafeClassList(body).toggle('fixed-top');
        if (!nullSafeClassList(el).contains(isVisibleClass)) {
            nullSafeClassList(listWrapper2).remove(isVisibleClass);
            nullSafeClassList(listWrapper3).remove(isVisibleClass);
            const menuLinks = nullSafeQuerySelectorAll(mobileMenu,"a");
            nullSafeForEach(menuLinks,function (menuLink) {
                nullSafeClassList(menuLink).remove(isActiveClass);
            });
        }
    }

    function changeLanguageOnMobile(switcher){
        window.open( switcher.options[ switcher.selectedIndex ].value, '_self');
    }

    function goBackOneLevel(el) {
        const parent = el.closest(".list-wrapper");
        // set all tabindexes in parent and list before to 0
        setTabIndexOnAllChildrenToActive(parent);
        setTabIndexOnAllChildrenToActive(parent.previousElementSibling);
        languageSwitcherMobile[0].tabIndex = 0;
        if (loginLinkMobileNav) {
            loginLinkMobileNav.tabIndex = 0;
        }
        nullSafeClassList(parent).remove(isVisibleClass);
        nullSafeClassList(
            nullSafeQuerySelector(parent.previousElementSibling,".is-active"))
            .remove(isActiveClass);
    }

    function nullSafeAddEventListener(element, type, callback) {
        if (element) {
            element.addEventListener(type, callback);
        }
    }

    function nullSafeQuerySelectorAll(element, selector) {
        if (element) {
            return element.querySelectorAll(selector);
        }
    }

    function nullSafeQuerySelector(element, selector) {
        if (element) {
            return element.querySelector(selector);
        }
    }

    function nullSafeClassList(element) {
        if (element) {
            return element.classList;
        } else {
            return new DOMTokenList();
        }
    }

    function nullSafeForEach(element, lambda) {
        if (element) {
            element.forEach(lambda)
        }
    }
});