import {basePath, contextPath, currentLang} from "./websiteConstants";

document.addEventListener('DOMContentLoaded', () => {

    if (document.querySelectorAll('.search-page').length < 1) {
        return;
    }

    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let currentPage = 1;

    let filterButtons = document.querySelectorAll('.search-page .filter-row button[name="filter"]');
    filterButtons.forEach(item => item.addEventListener('click', e => {
        params.set("filter", e.target.value);
        params.delete("page");
        window.location.search = params.toString();
    }));


    let filterSelect = document.querySelectorAll('.search-page .filter-row .filter-mobile .form-select');
    filterSelect.forEach(item => item.addEventListener('change', e => {
        params.set("filter", e.target.value);
        params.delete("page");
        window.location.search = params.toString();
    }));

    let paginationButtons = document.querySelectorAll('.search-page .paginationArea .page-number-button');
    if (paginationButtons !== null) {
        paginationButtons.forEach(item => item.addEventListener('click', e => {
            params.set("page", e.target.value);
            window.location.search = params.toString();
        }));
    }

    let previousPageButton = document.querySelector('.search-page .paginationArea .previous-page-button');
    if (previousPageButton !== null) {
        previousPageButton.addEventListener('click', () => {
            if (params.get("page") !== null) {
                currentPage = params.get("page");
            }
            if (currentPage && currentPage > 1) {
                params.set("page", parseInt(currentPage) - 1);
                window.location.search = params.toString();
            }
        })
    }

    let nextPageButton = document.querySelector('.search-page .paginationArea .next-page-button');
    if (nextPageButton !== null) {
        nextPageButton.addEventListener('click', () => {
            if (params.get("page") !== null) {
                currentPage = params.get("page");
            }
            if (currentPage) {
                params.set("page", parseInt(currentPage) + 1);
                window.location.search = params.toString();
            }
        })
    }
})