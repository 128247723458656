export function buildLeafletMap(contactBox) {
    let coordinateE = parseFloat(contactBox.dataset.coordinatesE);
    let coordinateN = parseFloat(contactBox.dataset.coordinatesN);
    let attributionText = contactBox.dataset.attributionText;
    let locationName = contactBox.dataset.locationName;

    // configuration for lv95 coordinates. Please do not change.
    let lv95_zg = {
        epsg: 'EPSG:2056',
        def: '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs ',
        resolutions: [26.45833332, 19.84374999, 13.22916666, 10.583333328, 7.937499996, 6.61458333, 5.291666664, 3.968749998, 3.307291665, 2.6458333322,
            1.9843749999, 1.3229166666, 0.7937499996, 0.6614583333, 0.5291666667, 0.3968749998, 0.2645833333, 0.1984374999, 0.1322916667, 0.0793749999,
            0.0661458333, 0.0529166666, 0.0396874999, 0.0264583333, 0.0132291667],
        origin: [2670000, 1235000],
    }

    // coordinate reference system for lv95
    let crs = new L.Proj.CRS(lv95_zg.epsg, lv95_zg.def, {
        resolutions: lv95_zg.resolutions,
        origin: lv95_zg.origin
    });

    let map_init_coordinates_wgs84 = crs.unproject(L.point(coordinateE, coordinateN));
    let map_init_zoom_level = 12;
    let marker_coordinates_wgs84 = map_init_coordinates_wgs84;

    // define a custom icon
    const zg_icon = L.divIcon({
        html: `<div class="map-marker"></div>`,
        iconAnchor: [0, 0],
    });

    contactBox.querySelectorAll('.map').forEach(function (singleMap) {
        // create the map
        let map = L.map(singleMap, {
            crs: crs,
            maxZoom: lv95_zg.resolutions.length,
            // scrollWheelZoom: false,
            // touchZoom: false
            dragging: !L.Browser.mobile,
            tap: false
        }).setView(map_init_coordinates_wgs84, map_init_zoom_level);

        // add the zug layer to the map
        L.tileLayer('https://services.geo.zg.ch/tc/wmts/1.0.0/zg.ortsplan_leicht_plus/default/zg/{z}/{y}/{x}.png', {
            tileSize: 512,
            maxZoom: lv95_zg.resolutions.length,
            id: 'zg.ortsplan_leicht_plus',
        }).addTo(map);

        // set the marker
        L.marker(marker_coordinates_wgs84, {icon: zg_icon, alt: locationName, title: locationName}).addTo(map);

        // set attribution Text
        map.attributionControl.addAttribution(attributionText);
        map.attributionControl.setPrefix(false);

        // GPS Location plugin
        let gps = new L.Control.Gps({
            //autoActive:true,
            autoCenter: true
        });
        gps.addTo(map);
    });
}