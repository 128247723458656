import Swiper, {Navigation, Pagination, A11y, Keyboard, EffectFade, Lazy, Controller } from 'swiper';

Swiper.use([Navigation, Pagination, A11y, Keyboard, EffectFade, Lazy, Controller]);

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelectorAll('.gallery-slider').length > 0) {
        const gallerySlider = document.querySelector('.row.gallery-slider');
        const prevSlideText = gallerySlider.dataset.prevSlide;
        const nextSlideText = gallerySlider.dataset.nextSlide;
        const firstSlideText = gallerySlider.dataset.firstSlide;
        const lastSlideText = gallerySlider.dataset.lastSlide;
        const paginationBulletText = gallerySlider.dataset.paginationBullet;
        let gallerySwipersOnPage = [];

        let observer = new IntersectionObserver(function (entries) {
            entries.forEach((element, index)=> {
                if (element.isIntersecting === true) {
                    resizeAllImageTexts(element.target, gallerySwipersOnPage[index]);
                }
            });
        }, {threshold: [0.8]});

        document.querySelectorAll('.row.gallery-slider').forEach(function (gallerySwiper) {
            gallerySwipersOnPage.push(initGallerySwiper(gallerySwiper, prevSlideText, nextSlideText, lastSlideText, firstSlideText, paginationBulletText, observer));
        });

        // show more text on mobile
        document.querySelectorAll('.btn-read-more').forEach(function (readMoreButton) {
            readMoreButton.addEventListener("click", function () {
                showMoreText(readMoreButton, gallerySwipersOnPage);
            });
        });

        document.querySelectorAll('.btn-read-less').forEach(function (readLessButton) {
            readLessButton.addEventListener("click", function () {
                showLessText(readLessButton, gallerySwipersOnPage);
            });
        });

        // init modal galleryModal on modal open
        document.querySelectorAll('.galleryModal').forEach(function (galleryModal, index) {
            galleryModal.addEventListener("shown.bs.modal", function () {
                let imageSlider = initGalleryModalImageSlider(this, prevSlideText, nextSlideText, lastSlideText, firstSlideText, paginationBulletText, gallerySwipersOnPage, index);
                let textSlider = initGalleryModalTextSlider(this, prevSlideText, nextSlideText, lastSlideText, firstSlideText, paginationBulletText, gallerySwipersOnPage, index);

                imageSlider.controller.control = textSlider;
                textSlider.controller.control = imageSlider;
            })
        });

        // destroy modal galleryModal on modal close
        document.querySelectorAll('.galleryModal').forEach(function (galleryModal, index) {
            galleryModal.addEventListener("hidden.bs.modal", function () {
                let number = this.querySelector('.gallery-modal-text-slider').swiper.activeIndex;
                setTimeout(function() {
                    gallerySwipersOnPage[index].slideTo(number, 400, true);
                }, 200);
                destroyGalleryModalImageSlider(this);
                destroyGalleryModalTextSlider(this);
            });
        });
    }
});

function showMoreText(readMoreButton, gallerySwipersOnPage) {
    // Hide truncated-text container
    readMoreButton.parentElement.parentElement.style.display = "none";

    //show full-text container
    let fullTextContainer = readMoreButton.parentElement.parentElement.nextElementSibling;
    fullTextContainer.setAttribute('style', 'display:inline !important;');

    // move pagination down
    gallerySwipersOnPage.forEach(function (gallerySwiperOnPage) {
        gallerySwiperOnPage.update();
    })
}

function showLessText(readLessButton, gallerySwipersOnPage) {
    //hide full-text container
    readLessButton.parentElement.style.display = "none";

    //show truncated-text container
    let truncatedTextContainer = readLessButton.parentElement.previousElementSibling;
    truncatedTextContainer.setAttribute('style', 'display:inline !important;');

    // move pagination back up
    gallerySwipersOnPage.forEach(function (gallerySwiperOnPage) {
        gallerySwiperOnPage.update();
    })
}

function setImageTextWidth(slide) {
    if (slide.querySelector('.image-text') != null) {
        slide.querySelector('.image-text').style.width = slide.querySelector('img').offsetWidth + "px";
    }
}

function resizeAllImageTexts(gallerySlider, swiper) {
    setTimeout(() => {
        const slides = gallerySlider.querySelectorAll('.swiper-slide');
        slides.forEach(function (slide) {
            setImageTextWidth(slide);
        });
        swiper.update();
    }, 200);
}

function initGallerySwiper(gallerySwiper, prevSlideText, nextSlideText, lastSlideText, firstSlideText, paginationBulletText, observer){
    let buttonNext = gallerySwiper.querySelector('.gallery-swiper-button-next');
    let buttonPrev = gallerySwiper.querySelector('.gallery-swiper-button-prev');
    let pagination = gallerySwiper.querySelector('.gallery-swiper-pagination');
    let swiperEl = gallerySwiper.querySelector('.gallery-swiper');

    let swiper = new Swiper(swiperEl, {
        slidesPerView: 'auto',
        spaceBetween: 8,
        simulateTouch: true,
        speed: 400,
        autoHeight: false,
        slidesOffsetAfter: 8,

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },

        // Breakpoints for >=
        breakpoints: {
            720: {
                spaceBetween: 24,
            },
            1025: {
                spaceBetween: 32
            },
        },

        navigation: {
            nextEl: buttonNext,
            prevEl: buttonPrev,
            disabledClass: 'btn-disabled',
        },

        pagination: {
            el: pagination,
            type: 'bullets',
            bulletActiveClass: 'gallery-swiper-pagination-bullet-active',
            clickable: true,
        },

        a11y: {
            enabled: true,
            prevSlideMessage: prevSlideText,
            nextSlideMessage: nextSlideText,
            lastSlideMessage: lastSlideText,
            firstSlideMessage: firstSlideText,
            paginationBulletMessage: paginationBulletText + ' {{index}}',
            slideLabelMessage: '{{index}} / {{slidesLength}}',
        },
        on: {
            reachEnd: function () {
                setTimeout(() => {
                    this.snapGrid = [...this.slidesGrid];
                    const slides = gallerySwiper.querySelectorAll('.swiper-slide');
                    const lastSlide = slides[slides.length - 1]
                    if (slides.length > 2) {
                        gallerySwiper.querySelector('.current-slide-number').innerText = slides.length;
                    }
                    if (lastSlide.querySelector('.image-text') != null) {
                        lastSlide.querySelector('.image-text').style.width = lastSlide.offsetWidth + "px";
                    }
                }, 100);
            },
            init: function () {
                const slides = gallerySwiper.querySelectorAll('.swiper-slide');
                gallerySwiper.querySelector('.slide-count').innerText = slides.length;
                gallerySwiper.querySelector('.current-slide-number').innerText = "1";
                gallerySwiper.querySelector('.number-separator').innerText = "/";
                resizeAllImageTexts(gallerySwiper, this);
                observer.observe(gallerySwiper);
            },
            slideChange: function () {
                setTimeout(() => {
                    const slides = gallerySwiper.querySelectorAll('.swiper-slide');
                    gallerySwiper.querySelector('.current-slide-number').innerText = this.activeIndex + 1;
                    if (this.activeIndex + 1 < slides.length) {
                        setImageTextWidth(slides[this.activeIndex + 1]);
                        this.update();
                    }
                }, 150);
            },
        },
    });

    return swiper;
}

function initGalleryModalImageSlider(galleryModal, prevSlideText, nextSlideText, lastSlideText, firstSlideText, paginationBulletText, gallerySwipersOnPage, gallerySwipersOnPageIndex) {
    const buttonNext = galleryModal.querySelector('.gallery-navigation-modal-next');
    const buttonPrev = galleryModal.querySelector('.gallery-navigation-modal-prev');
    const pagination = galleryModal.querySelector('.gallery-fraction-pagination');
    const swiperEl = galleryModal.querySelector('.gallery-modal-image-slider');
    const currentIndexOfParentSlider = gallerySwipersOnPage[gallerySwipersOnPageIndex].activeIndex;

    let swiper = new Swiper(swiperEl, {
        slidesPerView: 1,
        simulateTouch: true,
        speed: 400,
        effect: 'fade',
        autoHeight: true,
        preloadImages: false,
        initialSlide: currentIndexOfParentSlider,

        lazy: {
            enabled: true,
            loadPrevNext: true,
            loadOnTransitionStart: true,
        },

        fadeEffect: {
            crossFade: true
        },

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },

        navigation: {
            nextEl: buttonNext,
            prevEl: buttonPrev,
            disabledClass: 'btn-disabled',
        },

        pagination: {
            el: pagination,
            type: 'fraction',
        },

        a11y: {
            enabled: true,
            prevSlideMessage: prevSlideText,
            nextSlideMessage: nextSlideText,
            lastSlideMessage: lastSlideText,
            firstSlideMessage: firstSlideText,
            paginationBulletMessage: paginationBulletText + ' {{index}}',
            slideLabelMessage: '{{index}} / {{slidesLength}}',
        },
        on: {
            init: function () {
                this.lazy.loadInSlide(currentIndexOfParentSlider+1);
                animateSlider(this, "in");
            },
            lazyImageReady: function (swiper) {
                setTimeout(function() {
                    swiper.update();
                }, 200);

            },
        },
    });

    swiper.updateSize();
    return swiper;
}

function initGalleryModalTextSlider(galleryModal, prevSlideText, nextSlideText, lastSlideText, firstSlideText, paginationBulletText, gallerySwipersOnPage, gallerySwipersOnPageIndex) {
    const swiperEl = galleryModal.querySelector('.gallery-modal-text-slider');
    const currentIndexOfParentSlider = gallerySwipersOnPage[gallerySwipersOnPageIndex].activeIndex;

    let swiper = new Swiper(swiperEl, {
        slidesPerView: 1,
        simulateTouch: true,
        speed: 400,
        effect: 'fade',
        autoHeight: true,
        initialSlide: currentIndexOfParentSlider,

        fadeEffect: {
            crossFade: true
        },

        on: {
            init: function () {
                animateSlider(this, "in");
            },
        },
    });

    return swiper;
}

function destroyGalleryModalTextSlider(galleryModal) {
    const textSlider = galleryModal.querySelector('.gallery-modal-text-slider').swiper;
    animateSlider(textSlider, "out");
    textSlider.destroy(true, true);
}

function destroyGalleryModalImageSlider (galleryModal) {
    const imageSlider = galleryModal.querySelector('.gallery-modal-image-slider').swiper;
    animateSlider(imageSlider, "out");
    imageSlider.destroy(true, true);
}

function animateSlider (slider, direction) {
    const opacityValue = direction === "in" ? "100%" : "0%";

    slider.$el[0].animate(
        [
            { opacity: opacityValue},
        ], {
            delay: 200,
            duration: 200,
            iterations: 1,
            fill: 'forwards',
        }
    );

}