const ACCEPTANCE_COOKIE_NAME = 'zgAcceptCookies';

function getCookieValue(name) {
    const match = document.cookie.split('; ').find((row) => row.startsWith(name));

    return match && match.split('=')[1];
}

function readCookieForAcceptance() {
    return getCookieValue(ACCEPTANCE_COOKIE_NAME);
}

function setCookieForAcceptance() {
    document.cookie = `${ACCEPTANCE_COOKIE_NAME}=ACCEPTED; max-age=${365 * 24 * 60 * 60}; path=/; same-site=strict; secure`;
}

$(() => {
    const cookieBanner = document.querySelector('#cookie-banner');

    if (cookieBanner != null) {
        if (readCookieForAcceptance() !== 'ACCEPTED') {
            cookieBanner.classList.add('pending');
        }
        document.querySelector('#cookie-banner .cookie-banner_accept-link').addEventListener('click', () => {
            setCookieForAcceptance();

            // hide cookie banner by first animating out and then applying display: none after animation has finished
            cookieBanner.classList.remove('pending');
            cookieBanner.classList.add('accepted');
            setTimeout(() => cookieBanner.classList.add('d-none'), 1000);
        });
    }
});