import {basePath, contextPath, currentLang} from "./websiteConstants";

document.addEventListener('DOMContentLoaded', () => {

    if (document.querySelectorAll('.statementsPage').length < 1 || document.querySelectorAll('.statementsArea').length < 1) {
        return;
    }
    let lang = currentLang;

    //pagination
    const numberPerPage = 12;
    let currentPage = 1;
    let selectedStatementType = "all";

    //get statements
    const endpoint = basePath + contextPath + '/.rest/delivery/statements';
    const baseQueryString = '?lang=' + lang + '&limit=' + numberPerPage + '&orderBy=date%20desc';
    let baseQuery = new URLSearchParams(baseQueryString);

    let searchTerm = '';
    let total;

    //format dates
    const dateOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};

    //translate labels
    const publishedOn = {
        de: 'Publiziert am',
        en: 'Published on',
        fr: 'Publié le',
        it: 'Pubblicato il'
    };
    const timeLimit = {
        de: 'Frist bis',
        en: 'Time limit until',
        fr: 'Délai jusqu\'au',
        it: 'Scadenza fino al'
    };
    const allTitle = {
        de: 'Alle Vernehmlassungen',
        en: 'All Statements',
        fr: 'Toutes les consultations',
        it: 'Tutte le consultazioni'
    };
    const invitationTitle = {
        de: 'Kantonale Vorlagen',
        en: 'Cantonal proposals',
        fr: 'Projets cantonaux',
        it: 'Bollette cantonali'
    };
    const answerTitle = {
        de: 'Vernehmlassung/ Anhörung/ Konsultation zu',
        en: 'Consultation/ Hearing/ Consultation on',
        fr: 'Consultation/ audition/ consultation sur',
        it: 'Consultazione/ Audizione/ Consultazione su'
    };
    const addressees = {
        de: 'Adressaten: ',
        en: 'Addressees: ',
        fr: 'Destinataires: ',
        it: 'Destinatari: '
    };
    const answerTo = {
        de: 'Antwort an: ',
        en: 'Answer to: ',
        fr: 'Réponse à: ',
        it: 'Rispondi a: '
    };

    const noDeadline = {
        de: 'Keine Frist',
        en: 'No deadline',
        fr: 'Pas de délai',
        it: 'Nessuna scadenza'
    };

    /**
     * @param {URLSearchParams} sp
     * @param {string} prop
     * @param {string} value
     */
    const setOrRemoveQueryProperty = (sp, prop, value) => {
        if (value) {
            sp.set(prop, value);
        } else {
            sp.delete(prop);
        }
    }

    // Filter Elements
    const filterMobile = document.querySelector('.statements-list .filter-mobile select');
    filterMobile.addEventListener("change", function () {
        currentPage = 1;
        selectedStatementType = this.options[this.selectedIndex].value;
        if (selectedStatementType != "all")
            setOrRemoveQueryProperty(baseQuery, 'statementType', selectedStatementType);
        else
            setOrRemoveQueryProperty(baseQuery, 'statementType', '');
        fetchStatements();
    });

    const filterDesktop = document.querySelectorAll('.statements-list .radio-item .radio-item-input');
    filterDesktop.forEach(function (radio) {
        radio.addEventListener("change", function () {
            currentPage = 1;
            selectedStatementType = this.value;
            processStatementSelection(selectedStatementType);
        });
    });

    const processStatementSelection = (selectedStatementType) => {
        if (selectedStatementType != "all")
            setOrRemoveQueryProperty(baseQuery, 'statementType', selectedStatementType);
        else
            setOrRemoveQueryProperty(baseQuery, 'statementType', '');

        fetchStatements();
    }

    const searchField = document.querySelector('.statements-list input[type=search]');

    searchField.addEventListener('keyup', () => {

        searchTerm = '*' + searchField.value + '*';
        setOrRemoveQueryProperty(baseQuery, 'q', searchTerm);
        fetchStatements();
    });

    const fetchStatements = () => {
        const offset = (currentPage - 1) * numberPerPage;
        baseQuery.set('offset', offset.toString());
        
        // Delivery API will return a 500 if only wildcards are used https://docs.magnolia-cms.com/product-docs/6.2/developing/api/delivery-api/
        const searchParam = baseQuery.get('q');
        if(searchParam && searchParam === '**') {
            setOrRemoveQueryProperty(baseQuery,'q', '');
        }

        let query = endpoint + '?' + baseQuery.toString();

        fetch(query)
            .then((response) => response.json())
            .then((data) => {
                total = data.total;
                $('.statements-list .statementsArea').html(data.results.map((statementsItem) => buildListItem(statementsItem)));
                $('.statements-list .paginationArea').html(buildPagination());
                $('.statements-list .tableHeaderArea').html(buildTableHeader());
                if (data.total > 0) {
                    document.querySelector('#page-' + currentPage).classList.add('active');
                }
            });
    };

    const buildListItem = (statementsItem) => {
        let publishDate = statementsItem.date !== undefined ? new Date(statementsItem.date).toLocaleDateString('de-CH', dateOptions) : '';
        let endDate = statementsItem.dateEnd !== undefined ? new Date(statementsItem.dateEnd).toLocaleDateString('de-CH', dateOptions) : noDeadline[lang];
        let addresseeOrAnswerTo = statementsItem.addresseeOrAnswerTo !== undefined ? statementsItem.addresseeOrAnswerTo : '';
        let title = statementsItem.title !== undefined ? statementsItem.title : '';
        let addresseeOrAnswerToBefore = '';
        addresseeOrAnswerToBefore = statementsItem.statementType !== undefined && statementsItem.statementType === "answer" ? answerTo[lang] : addressees[lang];
        let link = 'vernehmlassungen/vernehmlassung~' + statementsItem['@path'].replaceAll('/', '_') + '~';

        return `<div class="col-12">
                    <div class="row py-16 py-lg-32">
                        <div class="col-2 d-none d-md-block">
                            <p class="date">${publishDate}</p>
                        </div>
                        <div class="col-2 d-none d-md-block">
                            <p class="date">${endDate}</p>
                        </div>
                        <div class="col-12 d-md-none">
                            <p class="date">${publishDate} / ${endDate}</p>
                        </div>
                        <div class="col-12 col-md-8">
                            <a href="${link}" target="_self" class="text-decoration-none">
                                <p class="title">${title}</p>
                                <p>${addresseeOrAnswerToBefore} ${addresseeOrAnswerTo}</p>
                            </a>
                        </div>
                    </div>
                    <hr />
                </div>`
    }

    const buildPagination = () => {
        const numbersDiv = document.createElement('div');
        numbersDiv.classList.add('d-flex', 'justify-content-start');

        const buttonsDiv = document.createElement('div');
        buttonsDiv.classList.add('d-flex', 'justify-content-start');

        const lastPage = Math.ceil(total / numberPerPage);
        const paginationDiv = document.createElement('div');
        paginationDiv.classList.add('d-flex', 'overflow-auto', 'flex-wrap', 'justify-content-md-center');
        const previousButton = document.createElement('button');
        previousButton.classList.add('btn', 'btn-icon-only', 'arrowLeft', 'pagination-button', 'text-color');
        previousButton.ariaLabel = "Vorherige Seite";
        if (currentPage === 1) {
            previousButton.disabled = true;
        }
        previousButton.addEventListener('click', () => {
            currentPage--;
            fetchStatements()
        })
        buttonsDiv.appendChild(previousButton);
        const nextButton = document.createElement('button');
        nextButton.classList.add('btn', 'btn-icon-only', 'arrowRight', 'pagination-button', 'text-color');
        nextButton.ariaLabel = "Nächste Seite";
        if (currentPage === lastPage) {
            nextButton.disabled = true;
        }
        nextButton.addEventListener('click', () => {
            currentPage++;
            fetchStatements()
        })
        buttonsDiv.appendChild(nextButton);

        for (let i = 1; i <= lastPage; i++) {
            // Print Page Number Button only for first,last and the 3 middle Elements if more than 4 pages
            // Examples: 1 2 3 ... 15  | 1 ... 8 9 10 ... 16 | 1 ... 13 14 15 16

                // Print the page Buttons
                if(lastPage < 4 || (i === 1 || i === currentPage - 1 || i === currentPage || i === currentPage + 1 || i === lastPage || (currentPage === 1 && i === 3) || (currentPage === lastPage && (i === lastPage - 1 || i === lastPage - 2)))) {
                    const button = document.createElement('button');
                    button.classList.add('btn', 'page-number-button', 'mb-0');
                    button.innerText = i.toString().padStart(2, '0');
                    button.id = 'page-' + i;
                    button.ariaLabel = "Seite " + i;
                    button.addEventListener('click', () => {
                        currentPage = i;
                        fetchStatements()
                    });
                    numbersDiv.appendChild(button);
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
                if (lastPage > 4 && (currentPage < 4 && i === 4 || currentPage >= lastPage - 2 && i === 1 || (currentPage > 3 && currentPage < lastPage -2) && (i === currentPage - 2 || i === currentPage + 2))) {
                    // Print the dots
                    const dots = document.createElement('p');
                    dots.classList.add('btn', 'page-number-button');
                    dots.innerText = '...';
                    numbersDiv.appendChild(dots);
                }



        }
        paginationDiv.appendChild(buttonsDiv);
        paginationDiv.appendChild(numbersDiv);
        return paginationDiv;
    }

    const buildTableHeader = () => {
        let publishedHeading = publishedOn[lang];
        let timeLimitHeading = timeLimit[lang];
        let previewHeading = "";

        switch (selectedStatementType) {
            case "statement":
                previewHeading = invitationTitle[lang];
                break;
            case "answer":
                previewHeading = answerTitle[lang];
                break;
            default:
                previewHeading = allTitle[lang];
        }

        return `<div class="col-12 pt-32 pt-md-40 pt-lg-80">
                    <div class="row d-none d-md-flex">
                        <div class="col-2">
                            <p class="text-uppercase">${publishedHeading}</p>
                        </div>
                        <div class="col-2">
                            <p class="text-uppercase">${timeLimitHeading}</p>
                        </div>
                        <div class="col-8">
                            <p class="text-uppercase">${previewHeading}</p>
                        </div>
                    </div>
                        <div class="row d-flex d-md-none">
                            <div class="col-12">
                              <p class="text-uppercase">${publishedHeading} / ${timeLimitHeading}</p> 
                        </div>
                    </div>    
                    <hr /> 
                </div>`
    }

    fetchStatements();

});