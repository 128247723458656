import {buildLeafletMap} from './buildLeafletMap';

document.addEventListener('DOMContentLoaded', e => {
    const contactBoxes = document.querySelectorAll('.component-contact-box');

    const resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0];
        if (entry) {
            if (window.matchMedia('(min-width: 720px)').matches) {
                // do the following whenever the body width changes
                if (contactBoxes.length > 0) {
                    contactBoxes.forEach(function (contactBox) {
                        setImageWrapperHeight(contactBox);
                    });
                }
            }
        }
    });

    let intersectionObserver = new IntersectionObserver(function (entries) {
        entries.forEach((element) => {
            if (element.isIntersecting === true) {
                setImageWrapperHeight(element.target)
            }
        });
    }, {threshold: [0.3]});

    if (contactBoxes.length > 0) {
        contactBoxes.forEach(function (contactBox) {
            intersectionObserver.observe(contactBox);

            if (contactBox.hasAttribute('data-coordinates-e') && contactBox.dataset.coordinatesE !== null && contactBox.dataset.coordinatesE.trim().length !== 0 &&
                contactBox.hasAttribute('data-coordinates-n') && contactBox.dataset.coordinatesN !== null && contactBox.dataset.coordinatesN.trim().length !== 0) {
                buildLeafletMap(contactBox);
            }
        });
        resizeObserver.observe(document.querySelector('body'));
    }
});

function setImageWrapperHeight(contactBox) {
    const windowWidth = window.innerWidth;
    let boxHeaderHeight = parseFloat(window.getComputedStyle(contactBox.querySelector('.bg-light'), null).getPropertyValue('padding-top')) + contactBox.querySelector('.box-header').offsetHeight;

    let normalPaddingBottom = 104;
    let contactImgHeight = 618;

    if (windowWidth > 720) {
        normalPaddingBottom = 152;
        contactImgHeight = 519;
    }

    // set offset top on image based on current image size
    if (window.matchMedia('(min-width: 720px)').matches && contactBox.querySelector('.contact-image')) {
        let contactImageCol = contactBox.querySelector('.contact-image-col');
        let contactImage = contactImageCol.querySelector('.contact-image');
        if (contactImage.querySelector('p')) {
            contactImgHeight = contactImgHeight + contactImage.querySelector('p').offsetHeight;
        }

        if (contactImgHeight > contactBox.offsetHeight - boxHeaderHeight) {
            contactImageCol.style.top = boxHeaderHeight.toString()-64 + 'px';
            contactBox.style.marginBottom = (boxHeaderHeight + normalPaddingBottom) + 'px';
        } else {
            let offsetTop = contactImgHeight * 0.2;
            contactImageCol.style.top = offsetTop.toString() + 'px';
            contactBox.style.marginBottom = (offsetTop + normalPaddingBottom) + 'px';
        }
    }

    // set offset top on map based on current screen size and contact box height
    if (contactBox.querySelector('.map')) {
        let contactMap = contactBox.querySelector('.map');
        if (windowWidth > 1024) {
            contactMap.style.top = (boxHeaderHeight + parseFloat(window.getComputedStyle(contactBox.querySelector('.address'), null).getPropertyValue('padding-top'))).toString() + 'px';
        } else if (windowWidth > 720) {
            contactMap.style.top = (contactBox.offsetHeight - contactMap.offsetHeight + (contactMap.offsetHeight * 0.2)) + 'px';
            contactBox.style.marginBottom = (contactMap.offsetHeight * 0.2 + normalPaddingBottom) + 'px';
        }

    }
}