function sortTable(n, t) {
    var table,
        rows,
        switching,
        i,
        x,
        y,
        shouldSwitch,
        dir,
        switchcount = 0;
    table = t;
    switching = true;
    dir = "asc";
    /*Make a loop that will continue until no switching has been done:*/
    while (switching) {
        switching = false;
        rows = table.getElementsByTagName("TR");
        /*Loop through all table rows (except the first, which contains table headers):*/
        for (i = 1; i < rows.length - 1; i++) {
            shouldSwitch = false;
            /*Get the two elements you want to compare and strip all html tags and white space from it:*/
            x = rows[i].getElementsByTagName("TD")[n].textContent.toLowerCase().replace(/[\n\r]+|[\s]{2,}/g, ' ').trim();
            y = rows[i + 1].getElementsByTagName("TD")[n].textContent.toLowerCase().replace(/[\n\r]+|[\s]{2,}/g, ' ').trim();

            // if both x and y is a number and not a date, convert it to number
            if (!isNaN(parseFloat(x)) && !x.includes(".") && !isNaN(parseFloat(y)) && !y.includes(".")) {
                x = parseFloat(x);
                y = parseFloat(y);
            } else if (x.split(".").length == 3 && y.split(".").length == 3){
                // it probably is a date
                let splitX = x.split(".");
                let splitY = y.split(".");
                x = new Date(splitX[2], splitX[1]-1, splitX[0]);
                y = new Date(splitY[2], splitY[1]-1, splitY[0]);

            } else {
                // otherwise append an "a" before every value so numbers and texts  can be compared. Numbers will then be grouped at top or bottom
                x = "a" + x;
                y = "a" + y;
            }

            /*check if the two rows should switch place, based on the direction, asc or desc:*/
            if (dir == "asc") {
                if (x > y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            } else if (dir == "desc") {
                if (x < y) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
        if (shouldSwitch) {
            /*If a switch has been marked, make the switch and mark that a switch has been done:*/
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            //Each time a switch is done, increase this count by 1:
            switchcount++;
        } else {
            /*If no switching has been done AND the direction is "asc", set the direction to "desc" and run the while loop again.*/
            if (switchcount == 0 && dir == "asc") {
                dir = "desc";
                switching = true;
            }
        }
    }
}

window.onload = function(){
    const tables = document.querySelectorAll('.section-table table');

    // append onclick and classes for each of them
    Array.from(tables).forEach(function (sortableTable){
        Array.from(sortableTable.getElementsByTagName('th')).forEach(function(th, i){
            th.innerHTML = '<p><span>' + th.innerHTML + '</span></p>';
            th.classList.add('unsorted');
            th.onclick = function () {
                // Toggle classes on this element
                th.classList.remove('unsorted');
                if(th.classList.contains("asc")) {
                    th.classList.replace("asc", 'desc');
                } else if (th.classList.contains("desc")){
                    th.classList.replace("desc", 'asc');
                } else {
                    th.classList.add("asc");
                }

                // set all other elements to unsorted
                let prevSibling = th.previousElementSibling;
                while(prevSibling) {
                    prevSibling.classList.remove('asc', 'desc');
                    prevSibling.classList.add('unsorted');
                    prevSibling = prevSibling.previousElementSibling;
                }
                let nextSibling = th.nextElementSibling;
                while(nextSibling) {
                    nextSibling.classList.remove('asc', 'desc');
                    nextSibling.classList.add('unsorted');
                    nextSibling = nextSibling.nextElementSibling;
                }

                // sort the table
                sortTable(i, sortableTable);
            }
        })
    })
}