const handleJobsiFrameLoad = () => {
    hideSpinnerOnLoad(document.querySelector('.iframe-jobs'));
    iFrameResize({
        inPageLinks: true,
        heightCalculationMethod: 'bodyOffset',
        //onResized: function () {scroll(0, 0);}
    });
}

const hideSpinnerOnLoad = (generaliFrame) => {
    generaliFrame.querySelector('.iframe-loader').classList.add('d-none');
}

// set iFrame source in code to prevent load event firing before the listener was attached (eg. when content is loaded from cache)
const onGeneraliFrameFound = (generaliFrame) => {
    let iFrameEl = generaliFrame.querySelector('.iframe-universal')
    iFrameEl.addEventListener('load', hideSpinnerOnLoad(generaliFrame));
    iFrameEl.src = generaliFrame.dataset.targetUrl;
}

const jobsiFrame = document.querySelector('.job-iframe');
if (jobsiFrame) {
    jobsiFrame.addEventListener('load', handleJobsiFrameLoad);
}

if (document.querySelector('.iframe')) {
    document.querySelectorAll('.iframe').forEach(function (iFrameComponent) {
        onGeneraliFrameFound(iFrameComponent);
    });
}