import Tab from 'bootstrap/js/dist/tab';

let touchstartX = 0
let touchendX = 0

document.addEventListener('DOMContentLoaded', e => {
    const body = document.querySelector('body');
    const heroHomeDossierTabs = document.querySelectorAll('.hero-home-dossier-tabs');
    const heroAreaHome = document.querySelector('section.heroAreaHome');

    const resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0];

        if (entry) {
            heroHomeDossierTabs.forEach(function (heroHomeDossierTabs) {
                getHeights(heroHomeDossierTabs);
            });
        }
    });

    if (heroHomeDossierTabs.length > 0){
        resizeObserver.observe(body);
        heroHomeDossierTabs.forEach(function (heroHomeDossierTabs) {
            setListeners(heroHomeDossierTabs);
            setTimeout(function() {
                autoPlayTabs(heroHomeDossierTabs);
            }, 5000)
        });
    }

    if(heroAreaHome){
        heroAreaHome.querySelector('input').focus();
    }
});

function setListeners (el) {
    let tabContent = el.querySelector('.tab-content');
    let bigSliderNav = el.querySelector('.big-slider-nav');
    let smallSliderNav = el.querySelector('.small-slider-nav');

    tabContent.addEventListener('touchstart', e => {
        touchstartX = e.changedTouches[0].screenX
    }, {passive: true});

    tabContent.addEventListener('touchend', e => {
        touchendX = e.changedTouches[0].screenX
        simulateSwipeOnTab(tabContent, bigSliderNav, smallSliderNav);
    }, {passive: true});
}

function getHeights(el){
    if(el.length > 0) {
        const wrapperWithImgHeight = el.querySelector(".tab-pane.active div.hero-image img").offsetHeight;
        const allWrappersWithImg = el.querySelectorAll(".tab-pane.active div.hero-image");

        allWrappersWithImg.forEach(function (wrapper) {
            setHeight(wrapperWithImgHeight, wrapper);
        });
    }
}

function setHeight(newHeight, el) {
    if(newHeight > 0){
        el.style.height = newHeight + 'px';
    }
}

function simulateSwipeOnTab(tabContent, bigNav, smallNav){
    let activeNode = tabContent.querySelector('.tab-pane.active');
    let parent = activeNode.parentNode;
    let indexOfActiveTab = Array.prototype.indexOf.call(parent.children, activeNode);

    //swipe left - show  item after
    if (touchendX < touchstartX) {
        if (activeNode.nextElementSibling != null) {
            let nextTabSmall = new Tab(smallNav.querySelectorAll('.nav-item')[indexOfActiveTab].nextElementSibling.querySelector('button'));
            let nextTabBig = new Tab(bigNav.querySelectorAll('.nav-item')[indexOfActiveTab].nextElementSibling.querySelector('button'));
            nextTabSmall.show();
            nextTabBig.show();
        }
    }

    // Swipe Right - show item before
    if (touchendX > touchstartX) {
        if (activeNode.previousElementSibling != null) {
            let tabBeforeSmall = new Tab(smallNav.querySelectorAll('.nav-item')[indexOfActiveTab].previousElementSibling.querySelector('button'));
            let tabBeforeBig = new Tab(bigNav.querySelectorAll('.nav-item')[indexOfActiveTab].previousElementSibling.querySelector('button'));
            tabBeforeSmall.show();
            tabBeforeBig.show();
        }
    }
}

function autoPlayTabs (heroHomeDossierTabs) {
    let tabContent = heroHomeDossierTabs.querySelector('.tab-content');
    let bigNav = heroHomeDossierTabs.querySelector('.big-slider-nav');
    let smallNav = heroHomeDossierTabs.querySelector('.small-slider-nav');
    let activeNode = tabContent.querySelector('.tab-pane.active');
    let parent = activeNode.parentNode;
    let indexOfActiveTab = Array.prototype.indexOf.call(parent.children, activeNode);
    let nextTabBig;
    let nextTabSmall;

    if(smallNav.querySelectorAll('.nav-item')[indexOfActiveTab].nextElementSibling != null) {
        nextTabSmall = new Tab(smallNav.querySelectorAll('.nav-item')[indexOfActiveTab].nextElementSibling.querySelector('button'));
        nextTabBig = new Tab(bigNav.querySelectorAll('.nav-item')[indexOfActiveTab].nextElementSibling.querySelector('button'));
    } else {
        nextTabSmall = new Tab(smallNav.querySelectorAll('.nav-item')[0].querySelector('button'));
        nextTabBig = new Tab(bigNav.querySelectorAll('.nav-item')[0].querySelector('button'));
    }

    nextTabSmall.show();
    nextTabBig.show();

    setTimeout(function() {
            autoPlayTabs(heroHomeDossierTabs);
    }, 5000)
}
