import {basePath, contextPath, currentLang} from "./websiteConstants";

document.addEventListener('DOMContentLoaded', () => {
    if(document.querySelector('.newsPage') != null) {
        const newsListElement = document.querySelector('.news-list');
        if (!newsListElement) {
            return;
        }

        const body = document.querySelector('body');
        let lang = currentLang;

        //pagination
        const numberPerPage = 10;
        let currentPage = 1;

        //get news
        const endpoint = basePath + contextPath + '/.rest/delivery/news';
        const baseQueryString = '?lang=' + lang + '&limit=' + numberPerPage + '&orderBy=date%20desc';
        let baseQuery = new URLSearchParams(baseQueryString);

        //filter news
        let searchTerm = '';
        let startDate = new Date(1970, 1, 1).toISOString().split('T')[0];
        let endDate = new Date().toISOString().split('T')[0];
        let total;

        //format news
        const dateOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
        const pressRelease = {
            de: 'Medienmitteilung',
            en: 'Press Release',
            fr: 'Communiqué de presse',
            it: 'Comunicato stampa'
        };
        const information = {
            de: 'Information',
            en: 'Information',
            fr: 'Information',
            it: 'Informazione'
        };
        let resultText = document.querySelector('.news-list .results-number').innerText;

        /**
         * @param {URLSearchParams} sp
         * @param {string} prop
         * @param {string} value
         */
        const setOrRemoveQueryProperty = (sp, prop, value) => {
            if (value) {
                sp.set(prop, value);
            } else {
                sp.delete(prop);
            }
        }

        const filterButton = document.querySelector('#filter-button');
        const closeFilterButton = document.querySelector('.btn-close-mobile-filters');
        const filterOverlay = document.querySelector('#mobile-filters');
        const heroArea = document.querySelector('.news-list .heroArea');

        const openFilters = () => {
            filterButton.classList.add('d-none');
            filterOverlay.classList.remove('d-none');
            heroArea.classList.add('d-none');
            body.classList.add('overflow-hidden', 'fixed-top');
        }
        filterButton.addEventListener('click', () => openFilters());

        const closeFilters = () => {
            filterButton.classList.remove('d-none');
            filterOverlay.classList.add('d-none');
            heroArea.classList.remove('d-none');
            body.classList.remove('overflow-hidden', 'fixed-top');
        }
        closeFilterButton.addEventListener('click', () => closeFilters());

        const switcherButton = document.querySelectorAll('.news-list .filterArea .switcher-button');
        const allNewsButton = document.querySelectorAll('.news-list .filterArea .switcher-button .all-news');
        const pressReleasesButton = document.querySelectorAll('.news-list .filterArea .switcher-button .press-release');
        const mobilePressReleaseButton = document.querySelectorAll('.news-list .filterArea #mobile-filters .switcher-button .press-release');

        mobilePressReleaseButton.forEach(el => el.addEventListener('click', (e) => {
            allNewsButton.forEach(item => item.nextElementSibling.classList.remove("bold"));
        }));

        switcherButton.forEach(item => item.querySelectorAll('input').forEach(el => el.addEventListener('click', (e) => {
            currentPage = 1;
            setOrRemoveQueryProperty(baseQuery, 'isPressRelease', e.target.classList.contains('press-release') ? 'pressRelease' : false);
            fetchNews();
        })));


        const officeSelect = document.querySelectorAll('.news-list .filterArea .office-select .form-select');
        const officeSelectOptions = document.querySelectorAll('.news-list .filterArea .office-select .form-select option');

        officeSelect.forEach((item) => item.addEventListener('change', (e) => {
            currentPage = 1;
            let selectedOffice = item.querySelector('option:checked').value;
            setOrRemoveQueryProperty(baseQuery, 'office', selectedOffice);
            fetchNews();
        }))

        const officeDropdownItems = document.querySelectorAll('.news-list .filterArea .office-dropdown .dropdown-item');
        const officeDropdownButton = document.querySelector('.news-list .filterArea .office-dropdown .btn-office-dropdown');
        officeDropdownItems.forEach((item) => item.addEventListener('click', () => {
            currentPage = 1;
            let selectedDirection = item.dataset.value;
            officeDropdownButton.innerText = item.innerText;
            setOrRemoveQueryProperty(baseQuery, 'office', selectedDirection);
            fetchNews();
        }))

        const directionSelect = document.querySelectorAll('.news-list .filterArea .direction-select .form-select');
        directionSelect.forEach((item) => item.addEventListener('change', () => {
            currentPage = 1;
            let selectedDirection = item.querySelector('option:checked').value;
            filterOfficesByDirection(selectedDirection, officeSelectOptions)

            setOrRemoveQueryProperty(baseQuery, 'direction', selectedDirection);
            fetchNews();
        }))

        const directionDropdownItems = document.querySelectorAll('.news-list .filterArea .direction-dropdown .dropdown-item');
        const directionDropdownButton = document.querySelector('.news-list .filterArea .direction-dropdown .btn-direction-dropdown');
        directionDropdownItems.forEach((item) => item.addEventListener('click', () => {
            currentPage = 1;
            let selectedDirection = item.dataset.value;
            filterOfficesByDirection(selectedDirection, officeDropdownItems)
            directionDropdownButton.innerText = item.innerText;
            setOrRemoveQueryProperty(baseQuery, 'direction', selectedDirection);
            fetchNews();
        }))

        // Only show offices that are subnodes of selected directions
        const filterOfficesByDirection = (directionPath, officeElements) => {
            officeElements.forEach((office) => {
                let officePath = office.dataset.value;
                if (officePath != undefined) {
                    if (officePath.includes(directionPath)) {
                        office.classList.remove("hidden");
                    } else {
                        office.classList.add("hidden");
                    }
                }
            })
        }
        const dateInputs = document.querySelectorAll('.news-list .filterArea input[type=date]');
        const dateFrom = document.querySelectorAll('.news-list .filterArea .start-date');
        const dateUntil = document.querySelectorAll('.news-list .filterArea .end-date');

        dateInputs.forEach((item) => item.addEventListener('change', e => {
            currentPage = 1;
            if (e.target.classList.contains('start-date')) {
                startDate = e.target.value;
            }

            if (e.target.classList.contains('end-date')) {
                endDate = e.target.value;
            }
            setOrRemoveQueryProperty(baseQuery, 'date[in]', startDate + '~' + endDate);
            fetchNews();
        }))

        const resetFilterButton = document.querySelector('.remove-filter-button');
        resetFilterButton.addEventListener('click', () => {
            resetFilters();
            baseQuery = new URLSearchParams(baseQueryString);
            fetchNews();
        })

        const officeDropdown = document.querySelector('#office-dropdown');
        const directionDropdown = document.querySelector('#direction-dropdown');
        const resetFilters = () => {
            allNewsButton.forEach(item => item.checked = true);
            pressReleasesButton.forEach(item => item.checked = false);
            directionSelect.forEach(item => item.value = "");
            officeSelect.forEach(item => item.value = "");
            dateFrom.forEach(item => item.value = "");
            dateUntil.forEach(item => item.value = "");
            officeDropdown.textContent = officeDropdown.dataset.defaultText;
            directionDropdown.textContent = directionDropdown.dataset.defaultText;
        }

        const searchField = document.querySelector('.news-list input[type=search]');

        searchField.addEventListener('keyup', () => {

            searchTerm = '*' + searchField.value + '*';
            setOrRemoveQueryProperty(baseQuery, 'q', searchTerm);
            fetchNews();
        })

        const fetchNews = () => {
            const offset = (currentPage - 1) * numberPerPage;
            baseQuery.set('offset', offset.toString());

            // Delivery API will return a 500 if only wildcards are used https://docs.magnolia-cms.com/product-docs/6.2/developing/api/delivery-api/
            const searchParam = baseQuery.get('q');
            if(searchParam && searchParam === '**') {
                setOrRemoveQueryProperty(baseQuery,'q', '');
            }

            let query = endpoint + '?' + baseQuery.toString();

            fetch(query)
                .then((response) => response.json())
                .then((data) => {
                    total = data.total;
                    document.querySelector('.news-list .results-number').innerText = total + ' ' + resultText;
                    $('.newsArea').html(data.results.map((newsItem, index) => buildListItem(newsItem, index)));
                    $('.paginationArea').html(buildPagination());
                    if (total > 0) {
                        document.querySelector('#page-' + currentPage).classList.add('active');
                        document.querySelector('.errorMessageArea').classList.add('d-none');
                    } else {
                        document.querySelector('.errorMessageArea').classList.remove('d-none');
                    }
                })
        };

        const truncate = (input, length) => input.length > length ? `${input.substring(0, length)}...` : input;

        const buildListItem = (newsItem, index) => {
            let newsDate = newsItem.date !== undefined ? new Date(newsItem.date).toLocaleDateString('de-CH', dateOptions) : '';
            let label = newsItem.isPressRelease !== undefined && newsItem.isPressRelease === 'pressRelease' ? pressRelease[lang] : information[lang];
            let link = 'news/news~' + newsItem['@path'].replaceAll('/', '_') + '~';
            let target = '_self';
            if(newsItem.informationType !== undefined && newsItem.informationType.field !== undefined) {
                if(newsItem.informationType.field === 'pageExtern') {
                    link = newsItem.informationType.linkExtern;
                    target = '_blank';
                } else if (newsItem.informationType.field === 'pageIntern'){
                    link = newsItem.informationType.linkIntern;
                }
            }
            let title = newsItem.titleShort !== undefined ? newsItem.titleShort : newsItem.title;
            let lead = newsItem.leadShort !== undefined ? newsItem.leadShort : newsItem.lead;
            let hasImage = newsItem.image !== undefined;
            let hasFocalPoint = hasImage && newsItem.image.focal?.point !== undefined;
            let hasMetaData = hasImage && newsItem.image.metadata !== undefined;
            let genericImageSource = hasImage ? newsItem.image['@link'] : '';
            let imageSrcS =  hasFocalPoint ? (newsItem.image.focal.point.urls.square).replace("_WIDTH_x_HEIGHT_", "80x80") : genericImageSource;
            let imageSrcL = hasFocalPoint ? (newsItem.image.focal.point.urls.square).replace("_WIDTH_x_HEIGHT_", "186x186") : genericImageSource;
            let imageName = hasMetaData && newsItem.image["@name"] !== undefined ? newsItem.image["@name"] : '';
            // prevent the case of an empty caption while name is set by setting the name as the caption if the caption does not exist (empty alt text and not empty title causes Accessibility issues)
            let imageCaption = hasMetaData && newsItem.image.metadata.caption !== undefined ? newsItem.image.metadata.caption : imageName;
            let imageHtmlS = (hasImage && imageSrcS) ? `<img class="news-image lazyload" src="${imageSrcS}" alt="${imageCaption}" title="${imageName}" width="200" height="200"/>` : '';
            let imageHtmlL = (hasImage && imageSrcL) ? `<img class="news-image lazyload" src="${imageSrcL}" alt="${imageCaption}" title="${imageName}" width="200" height="200"/>` : '';
            let isLast = index === numberPerPage - 1;
            let lastDivider = isLast ? '<hr/>' : '';

            return `<div class="col-12">
                    <hr/>
                    <a href="${link}"
                    ${target === '_blank' ? 'rel="noopener noreferrer"' : '' }
                    target="${target}" class="news-item my-32 my-lg-48" aria-label="${title}">
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <p class="date">${newsDate}</p>
                            </div>
                            <div class="col-12 col-md-6">
                                <p class="label mb-8">${label.toUpperCase()}</p>
                                <div class="row">
                                    <div class="col-8 col-md-12">
                                        <p class="h4 mb-8">${truncate(title, 77)}</p>
                                        <p>${truncate(lead, 197)}</p>
                                    </div>
                                    <div class="col-4 d-flex d-md-none">
                                        ${imageHtmlS}
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 d-none d-md-flex">
                                ${imageHtmlL}
                            </div>
                        </div>
                     </a>
                </div>
                ${lastDivider}`
        }

        const buildPagination = () => {
            const numbersDiv = document.createElement('div');
            numbersDiv.classList.add('d-flex', 'justify-content-start');

            const buttonsDiv = document.createElement('div');
            buttonsDiv.classList.add('d-flex', 'justify-content-start');

            const lastPage = Math.ceil(total / numberPerPage);
            const paginationDiv = document.createElement('div');
            paginationDiv.classList.add('d-flex', 'overflow-auto', 'flex-wrap', 'justify-content-md-center');
            const previousButton = document.createElement('button');
            previousButton.classList.add('btn', 'btn-icon-only', 'arrowLeft', 'pagination-button', 'text-color');
            previousButton.ariaLabel = "Vorherige Seite";
            if (currentPage === 1) {
                previousButton.disabled = true;
            }
            previousButton.addEventListener('click', () => {
                currentPage--;
                fetchNews()
            })
            buttonsDiv.appendChild(previousButton);
            const nextButton = document.createElement('button');
            nextButton.classList.add('btn', 'btn-icon-only', 'arrowRight', 'pagination-button', 'text-color');
            nextButton.ariaLabel = "Nächste Seite";
            if (currentPage === lastPage) {
                nextButton.disabled = true;
            }
            nextButton.addEventListener('click', () => {
                currentPage++;
                fetchNews()
            })
            buttonsDiv.appendChild(nextButton);

            for (let i = 1; i <= lastPage; i++) {
                // Print Page Number Button only for first,last and the 3 middle Elements if more than 4 pages
                // Examples: 1 2 3 ... 15  | 1 ... 8 9 10 ... 16 | 1 ... 13 14 15 16
                if(lastPage > 4) {
                    // Print the page Buttons
                    if(lastPage < 4 || (i === 1 || i === currentPage - 1 || i === currentPage || i === currentPage + 1 || i === lastPage || (currentPage === 1 && i === 3) || (currentPage === lastPage && (i === lastPage - 1 || i === lastPage - 2)))) {
                        const button = document.createElement('button');
                        button.classList.add('btn', 'page-number-button', 'mb-0');
                        button.innerText = i.toString().padStart(2, '0');
                        button.id = 'page-' + i;
                        button.ariaLabel = "Seite " + i;
                        button.addEventListener('click', () => {
                            currentPage = i;
                            fetchNews()
                        });
                        numbersDiv.appendChild(button);
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                    if (lastPage > 4 && (currentPage < 4 && i === 4 || currentPage >= lastPage - 2 && i === 1 || (currentPage > 3 && currentPage < lastPage -2) && (i === currentPage - 2 || i === currentPage + 2))) {
                        // Print the dots
                        const dots = document.createElement('p');
                        dots.classList.add('btn', 'page-number-button');
                        dots.innerText = '...';
                        numbersDiv.appendChild(dots);
                    }
                } else {
                    const button = document.createElement('button');
                    button.classList.add('btn', 'page-number-button', 'mb-0');
                    button.innerText = i.toString().padStart(2, '0');
                    button.id = 'page-' + i;
                    button.ariaLabel = "Seite " + i;
                    button.addEventListener('click', () => {
                        currentPage = i;
                        fetchNews()
                    });
                    numbersDiv.appendChild(button);
                }


            }
            paginationDiv.appendChild(buttonsDiv);
            paginationDiv.appendChild(numbersDiv);
            return paginationDiv;
        }

        fetchNews();
    }
})
