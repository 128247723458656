document.addEventListener('DOMContentLoaded', e => {
    const forms = document.querySelectorAll('.form-container');

    if (!forms.length > 0) {
        return;
    }

    const errorMessageValueMissing = forms[0].dataset.errorValueMissing;
    const errorMessageRangeUnderflow = forms[0].dataset.errorRangeUnderflow;
    const errorMessageRangeOverflow = forms[0].dataset.errorRangeOverflow;
    const errorMessageTooShort = forms[0].dataset.errorTooShort;
    const errorMessageTooLong = forms[0].dataset.errorTooLong;
    const errorMessageSelect = forms[0].dataset.errorSelect;

    function validate(input, error) {
        const validityState = input.validity;

        if (validityState.valueMissing) {
            if(input.type == "radio" || input.type == "checkbox") {
                input.setCustomValidity(errorMessageSelect);
                input.parentNode.parentNode.parentNode.classList.add("error");
                error.style.display = "block";
            } else if(input.classList.contains('form-select')) {
                input.setCustomValidity(errorMessageSelect);
                input.parentNode.parentNode.classList.add("error");
                error.style.display = "block";
            } else {
                input.setCustomValidity(errorMessageValueMissing);
                input.classList.add("error");
                error.style.display = "block";
            }

        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(errorMessageRangeUnderflow);
            input.classList.add("error");
            error.style.display = "block";
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(errorMessageRangeOverflow);
            input.classList.add("error");
            error.style.display = "block";
        } else if (validityState.tooLong) {
            input.setCustomValidity(errorMessageTooShort);
            input.classList.add("error");
            error.style.display = "block";
        } else if (validityState.tooShort) {
            input.setCustomValidity(errorMessageTooLong);
            input.classList.add("error");
            error.style.display = "block";
        } else if (validityState.badInput) {
            input.classList.add("error");
            error.style.display = "block";
        }  else if (validityState.typeMismatch) {
            input.classList.add("error");
            error.style.display = "block";
        } else {
            input.setCustomValidity('');
            if(input.type == "radio" || input.type == "checkbox") {
                input.parentNode.parentNode.parentNode.classList.remove("error");
            } if(input.classList.contains('form-select')) {
                input.parentNode.parentNode.classList.remove("error");
            } else {
                input.classList.remove("error");
            }
            if (error) {
                error.style.display = "none";
            }
        }

        // input.reportValidity();
        // error.style.display = "block";
        error.innerHTML = input.validationMessage;
    }

    function isChecked(checkboxes) {
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) return true;
        }

        return false;
    }

    function checkCheckboxValidity(checkboxes) {
        let error = checkboxes[0].parentNode.parentNode.querySelector('.checkbox-error');
        if (!isChecked(checkboxes)) {
            checkboxes[0].setCustomValidity(errorMessageSelect);
            checkboxes[0].parentNode.parentNode.parentNode.classList.add('error');
            error.style.display = "block";
            error.innerHTML = checkboxes[0].validationMessage;
        } else {
            checkboxes[0].setCustomValidity('');
            error.style.display = "none";
            error.innerHTML = "";
            checkboxes[0].parentNode.parentNode.parentNode.classList.remove('error');
        }
        checkboxes[0].reportValidity();
    }

    function checkSelectHasValue(select){
        if(select.value) {
            select.parentNode.parentNode.parentNode.classList.add("has-option");
        } else {
            select.parentNode.parentNode.parentNode.classList.remove("has-option");
        }
    }

    forms.forEach(form => {
        const checkboxesNames = form.querySelectorAll('.checkbox-group-required input[type=checkbox]');
        if (!checkboxesNames) {
            return;
        }
        const uniqueCheckboxNames = [...new Set(Array.from(checkboxesNames).map( e => e.name ))];

        uniqueCheckboxNames.forEach(uniqueCheckboxName => {
            let checkboxes = document.getElementsByName(uniqueCheckboxName);
            checkboxes.forEach(checkbox => {
                checkbox.addEventListener('change', function () {
                    checkCheckboxValidity(checkboxes);
                });
            });
        });
    });

    const inputs = document.querySelectorAll('input[type=text]:not([name="honeypot"]), input[type=number], textarea, input[type=tel], input[type=date], input[type=month], input[type=email], .form-select, input[type=radio], .checkbox-single-required input[type=checkbox]');

    inputs.forEach(input => {
        let error;
        if(input.classList.contains("form-check-input")) {
            error = input.parentNode.parentNode.querySelector(".checkbox-error");
            input.addEventListener("change", function(event) {
                validate(input, error);
            });
        } else {
            error = input.nextElementSibling;
        }

        input.addEventListener("invalid", function(event) {
            event.preventDefault();
            validate(input, error);
        });

        input.addEventListener("focus", function() {
            if(input.type === "radio") {
                input.parentNode.parentNode.parentNode.classList.remove("error");
            } if(input.classList.contains('form-select')) {
                input.parentNode.parentNode.classList.remove("error");
            } else {
                input.classList.remove("error");
                input.parentNode.classList.remove("error");
            }

            if (error) {
                error.style.display = "none";
            }

            input.setCustomValidity("");
        });

        input.addEventListener("focusout", function() {
            if (input && error)
                validate(input, error);
        });
    });

    const selects = document.querySelectorAll('select');

    selects.forEach(select => {
        checkSelectHasValue(select)
        select.addEventListener('change', function() {
            checkSelectHasValue(select)
        });
    });
});
