import {buildLeafletMap} from "./buildLeafletMap";

document.addEventListener('DOMContentLoaded', e => {
    let mapComponents = document.querySelectorAll(".map-component");
    if(mapComponents){
        mapComponents.forEach(function (mapComponents) {
            if (mapComponents.hasAttribute('data-coordinates-e') && mapComponents.dataset.coordinatesE !== null && mapComponents.dataset.coordinatesE.trim().length !== 0 &&
                mapComponents.hasAttribute('data-coordinates-n') && mapComponents.dataset.coordinatesN !== null && mapComponents.dataset.coordinatesN.trim().length !== 0) {
                buildLeafletMap(mapComponents);
            }
        });
    }
});