document.addEventListener('DOMContentLoaded', e => {
    const peopleCards = document.querySelectorAll('.people-cards .people-card');
    if (!peopleCards) {
        return;
    }

    const makeLinksFocusable = (e) => {
        let code = e.charCode || e.keyCode;
        if (Array.from(peopleCards).includes(document.activeElement) && (code === 32 || code === 13)) {
            let peopleCardLinks = document.activeElement.querySelectorAll('.people-card a');
            peopleCardLinks.forEach(el => el.tabIndex = 0);
        }
    }

    document.addEventListener('keydown', (e) => makeLinksFocusable(e))
})