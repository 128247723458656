const getAbsoluteHeight = (el) => {
    // Get the DOM Node if you pass in a string
    el = (typeof el === 'string') ? document.querySelector(el) : el;

    var styles = window.getComputedStyle(el);
    var margin = parseFloat(styles['marginTop']) +
        parseFloat(styles['marginBottom']);

    return Math.ceil(el.offsetHeight + margin);
}

document.addEventListener('DOMContentLoaded', e => {
    let inPageNavArea = document.querySelector('.inPageNavigationArea');
    let inPageNav = document.querySelector('#in-page-navigation');
    let body = document.querySelector('body');
    let mainContainer = document.querySelector('main .container');
    let navigationList = inPageNav && inPageNav.querySelector('.nav');
    let navigationListItems = navigationList && navigationList.querySelectorAll('.nav-item');
    let titleMobile = inPageNavArea && inPageNavArea.querySelector('.title-mobile');
    let elementAfterInPageNav = document.querySelector('.inPageNavigationArea + *');
    let inPageNavHasAnimated = false;
    let orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
    let pageWidth = window.innerWidth;
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let navJustClosed = false;

    const intersectionObserverOptions = {
        rootMargin: '0px 0px -20% 0px',
        threshold: 0.8
    }

    const intersectionObserver = new IntersectionObserver(function (entries) {
        entries.forEach((element, index) => {
            if (element.isIntersecting === true) {
                animateInPageNav();
            }
        });
    }, intersectionObserverOptions);

    // If the inPageNav is already in the viewport but not yet in the bottom 20%, it should automaticly animate after a few seconds
    const backUpObserverForTimeout = new IntersectionObserver((entries) => {
        entries.forEach((element, index) => {
            if (element.isIntersecting === true) {
                setTimeout(() => {
                    animateInPageNav();
                }, 2000)
            }
        });
    });

    if (inPageNavArea){
        intersectionObserver.observe(inPageNavArea);
        backUpObserverForTimeout.observe(inPageNavArea);
    }

    const animateInPageNav = () => {
        // Animate the in Page Nav once and keep it on the place
        if (!inPageNavHasAnimated && window.matchMedia('(min-width: 720px)').matches) {
            let containerStyles = window.getComputedStyle(mainContainer);
            const translateValueFromLeft = containerStyles.marginLeft;

            inPageNav.animate(
                [
                    {transform: 'translatex(-25px)'},
                    {transform: 'translatex(' + translateValueFromLeft + ')'}
                ], {
                    delay: 200,
                    duration: 600,
                    fill: 'forwards',
                    easing: 'cubic-bezier(0.57, 0, 0.25, 1)',
                    iterations: 1
                }
            );
            inPageNavHasAnimated = true;
        }
    }

    const buildMobileInPageNavigation = (heightInPageNavItem, scrollDirection = '') => {
        // check for scroll direction to prevent false opening or closing of the nav and to allow bigger
        // navs to open again when the user scrolls back to top (the first check in the else if does not work in
        // the case of a nav list that is longer than the window-height
        let elementAfterInPageNavPos = elementAfterInPageNav.getBoundingClientRect();
        if(inPageNav.getBoundingClientRect().bottom < inPageNav.querySelector('.nav-item a').offsetHeight && scrollDirection === 'down'){
          // Show sticky nav
            inPageNavArea.style.position = "sticky";
            titleMobile.classList.add('d-none');
            navigationList.classList.add('minified-list');
            inPageNav.classList.add('minified-nav');
            navJustClosed = true;
            setTimeout(function() {
                navJustClosed = false;
            }, 1000);
        } else if(!navJustClosed && inPageNav.querySelector('.nav-item').getBoundingClientRect().bottom < elementAfterInPageNavPos.top && scrollDirection === 'up') {
            // Hide Sticky nav
            inPageNavArea.style.position = "relative";
            inPageNavArea.style.top = "0px";
            titleMobile.classList.remove('d-none');
            navigationList.classList.remove('minified-list');
            inPageNav.classList.remove('minified-nav');
        }

        let activeNavigationLink = navigationList.querySelector('.nav-item .active');
        navigationList.querySelectorAll('li').forEach(e => e.classList.remove('active-li'));
        if (activeNavigationLink) {
            activeNavigationLink.parentElement.classList.add('active-li');
        }
    }

    const toggleNavigation = (event) => {
        if (event.target.classList.contains('active')) {
            event.preventDefault();
            navigationList.classList.toggle('expanded');
        } else {
            navigationList.classList.remove('expanded');
        }
    }

    if (inPageNav) {
        let heightInPageNav = inPageNav.offsetHeight;
        document.querySelector('html').style.scrollPaddingTop = heightInPageNav + 20 + 'px';

        // set up bootstrap scrollspy
        body.dataset.bsSpy = 'scroll';
        body.dataset.bsTarget = '#in-page-navigation';
        body.dataset.bsOffset = (visualViewport.height / 2);

        let activeNavIndex = 0;
        let containerMarginLeft = parseInt(window.getComputedStyle(mainContainer).marginLeft);
        const sleep = ms => new Promise(r => setTimeout(r, ms));

        // Bootstrap Bug: if scrollspy is set up on body, the event fires on window
        window.addEventListener('activate.bs.scrollspy', async (e) => {
            await sleep(500);
            navigationListItems.forEach((el, index) => {
                if (window.matchMedia('(min-width: 720px)'.matches) &&
                    navigationList.offsetWidth > visualViewport.width - containerMarginLeft &&
                    el.querySelector('a').getAttribute("href") === e.relatedTarget) {

                    if (index > activeNavIndex) {
                        inPageNavArea.scrollLeft += el.getBoundingClientRect().left - 32;
                    } else {
                        inPageNavArea.scrollLeft = (el.getBoundingClientRect().left + inPageNavArea.scrollLeft) - (inPageNavArea.offsetWidth / 2);
                    }

                    if (index === 0) {
                        inPageNavArea.scrollLeft = 0;
                    }

                    activeNavIndex = index;
                }
            });
        });

        // Allow scrolling on windows desktop browsers
        let isDown = false;
        let startX;
        let scrollLeft;

        inPageNavArea.addEventListener('mousedown', (e) => {
            isDown = true;
            startX = e.pageX - inPageNavArea.offsetLeft;
            scrollLeft = inPageNavArea.scrollLeft;
        });
        inPageNavArea.addEventListener('mouseleave', () => {
            isDown = false;
            inPageNavArea.classList.remove('isDragged');
        });
        inPageNavArea.addEventListener('mouseup', () => {
            isDown = false;
            inPageNavArea.classList.remove('isDragged');
        });
        inPageNavArea.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - inPageNavArea.offsetLeft;
            const walk = (x - startX);
            inPageNavArea.scrollLeft = scrollLeft - walk;
        });

        // check for orientation change and fix nav
        window.addEventListener('resize', () => {
            let orientationNew = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
            let pageWidthNew = window.innerWidth;

            // reload page when orientation changes or page resize goes over the mobile breakpoint
            if (orientationNew !== orientation || (pageWidthNew <= 720 && pageWidth > 720) || (pageWidthNew > 720 && pageWidth <= 720)) {
                location.reload();
            }

            pageWidth = pageWidthNew;
            orientation = orientationNew;
        });

        if (!window.matchMedia('(min-width: 720px)').matches) {
            let heightInPageNavItem = navigationList.querySelector('.nav-item').offsetHeight + 32;
            document.querySelector('html').style.scrollPaddingTop = heightInPageNavItem + 50 + 'px';
            navigationList.querySelectorAll('.nav-link').forEach(e => e.classList.remove('active'));
            buildMobileInPageNavigation(heightInPageNavItem);

            document.addEventListener('scroll', () => {
                let newScrollTop = window.pageYOffset || document.documentElement.scrollTop;
                let scrollDirection = '';

                if (newScrollTop > lastScrollTop){
                    scrollDirection = 'down';
                } else {
                    scrollDirection = 'up';
                }
                lastScrollTop = newScrollTop <= 0 ? 0 : newScrollTop;
                buildMobileInPageNavigation(heightInPageNavItem, scrollDirection);
            }, {passive: true});

            navigationListItems.forEach((el) => {
                el.addEventListener('click', event => toggleNavigation(event));
            });
        }
    }
});