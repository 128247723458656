// Alternative navigation code for microsites
document.addEventListener('DOMContentLoaded', e => {
    const body = nullSafeQuerySelector(document, 'body');

    if (!nullSafeClassList(body).contains('microsite')) {
        return;
    }

    const micrositeDropdownButtons = nullSafeQuerySelectorAll(document, '.micrositeMenuButton');
    const searchOverlayDropdownDesktopBtn = nullSafeQuerySelector(document, '#searchButtonNavDesktop');
    const closeMicrositeDropdownBtns = nullSafeQuerySelectorAll(document, '.btn-close-main-nav-microsites');
    const closeSearchOverlayDropdownDesktopBtn = nullSafeQuerySelector(document, '.btn-close-main-nav-search-overlay-desktop');
    const searchOverlayDropdownDesktop = nullSafeQuerySelector(document, '.search-menu-dropdown-desktop');
    const regularNavLinks = nullSafeQuerySelectorAll(document, 'a.btn-header-nav');

    if (closeMicrositeDropdownBtns) {
        nullSafeForEach(closeMicrositeDropdownBtns,closeMicrositeDropdownBtn => {
            nullSafeAddEventListener(closeMicrositeDropdownBtn,'click', function (e) {
                closeMicrositeDropdown(e.target.dataset.target);
            });
        });
    }

    if (closeSearchOverlayDropdownDesktopBtn) {
        nullSafeAddEventListener(closeSearchOverlayDropdownDesktopBtn,'click', function () {
            closeSearchOverlayDesktopDropdown();
        });
    }

    if (micrositeDropdownButtons) {
        nullSafeForEach(micrositeDropdownButtons,micrositeDropdown => {
            nullSafeAddEventListener(micrositeDropdown,'show.bs.dropdown', function (e) {
                closeSearchOverlayDesktopDropdown();
                nullSafeClassList(micrositeDropdown).remove('inactive');

                nullSafeForEach(micrositeDropdownButtons,micrositeDropdown => {
                    if (micrositeDropdown.id != e.target.id) {
                        closeMicrositeDropdown(micrositeDropdown.dataset.target)
                        nullSafeClassList(micrositeDropdown).add('inactive');
                    }
                })

                nullSafeForEach(regularNavLinks,navLink => {
                    nullSafeClassList(navLink).add('inactive');
                })
            });

            nullSafeAddEventListener(micrositeDropdown,'shown.bs.dropdown', function (e) {
                document.addEventListener('click', event => listenForOutsideClickOfMicrositeaNav(event, e.target.dataset.target));
            });
        });
    }

    if (searchOverlayDropdownDesktopBtn) {
        nullSafeAddEventListener(searchOverlayDropdownDesktopBtn,'show.bs.dropdown', function () {
            nullSafeForEach(micrositeDropdownButtons,micrositeDropdown => {
                closeMicrositeDropdown(micrositeDropdown.dataset.target)
            })
        });

        nullSafeAddEventListener(searchOverlayDropdownDesktopBtn,'shown.bs.dropdown', function () {
            nullSafeQuerySelector(searchOverlayDropdownDesktop,'input').focus();
            document.addEventListener('click', listenForOutsideClickOfSearchOverlay);
        });

        nullSafeAddEventListener(searchOverlayDropdownDesktopBtn,'hidden.bs.dropdown', function () {
            nullSafeForEach(micrositeDropdownButtons,micrositeDropdown => {
                nullSafeClassList(micrositeDropdown).remove("not-active")
            })
        })
    }

    function listenForOutsideClickOfSearchOverlay (clickTarget) {
        if (!searchOverlayDropdownDesktop.contains(clickTarget.target) && ![...clickTarget.target.classList].includes("btn")) {
            closeSearchOverlayDesktopDropdown();
        }
    }

    function listenForOutsideClickOfMicrositeaNav (clickTarget, micrositeTarget) {
        const micrositeMenuDropdown = document.getElementById(micrositeTarget + 'MenuButton');
        
        if (!micrositeMenuDropdown.contains(clickTarget.target) && ![...clickTarget.target.classList].includes("btn")) {
            closeMicrositeDropdown(micrositeMenuDropdown.dataset.target);
        }
    }

    function closeMicrositeDropdown(target) {
        const micrositeDropdownBtn = document.getElementById(target + "MenuButton")
        const micrositeDropdownMenu = document.getElementById(target + "Menu")
        // Close/toggle status of dropdown on click
        nullSafeClassList(micrositeDropdownBtn).remove('show');
        micrositeDropdownBtn.setAttribute('aria-expanded', 'false');
        // close main dropdown
        nullSafeClassList(micrositeDropdownMenu).remove('show');
        // remove Eventlistner for click outside of modal from body
        document.removeEventListener('click', (e) => listenForOutsideClickOfMicrositeaNav(e, target));
        nullSafeForEach(micrositeDropdownButtons,micrositeDropdown => {
            nullSafeClassList(micrositeDropdown).remove("inactive")
        })
        nullSafeForEach(regularNavLinks,navLink => {
            nullSafeClassList(navLink).remove('inactive');
        })
    }

    function closeSearchOverlayDesktopDropdown() {
        searchOverlayDropdownDesktopBtn.setAttribute('aria-expanded', 'false');
        nullSafeClassList(searchOverlayDropdownDesktopBtn).remove('show');
        nullSafeClassList(searchOverlayDropdownDesktop).remove('show');
    
        if (micrositeDropdownButtons) {
            nullSafeForEach(micrositeDropdownButtons,micrositeDropdown => {
                nullSafeClassList(micrositeDropdown).remove("not-active");
            });
        }
        document.removeEventListener('click', listenForOutsideClickOfSearchOverlay);
    }

    function nullSafeAddEventListener(element, type, callback) {
        if (element) {
            element.addEventListener(type, callback);
        }
    }

    function nullSafeQuerySelectorAll(element, selector) {
        if (element) {
            return element.querySelectorAll(selector);
        }
    }

    function nullSafeQuerySelector(element, selector) {
        if (element) {
            return element.querySelector(selector);
        }
    }

    function nullSafeClassList(element) {
        if (element) {
            return element.classList;
        } else {
            return new DOMTokenList();
        }
    }

    function nullSafeForEach(element, lambda) {
        if (element) {
            element.forEach(lambda)
        }
    }
});