import Dropdown from 'bootstrap/js/dist/dropdown';

document.addEventListener('DOMContentLoaded', e => {
    const body = nullSafeQuerySelector(document, 'body');

    // If microsite: script in /microsites/mainNavigation.js is used
    if (nullSafeClassList(body).contains('microsite')) {
        return;
    }

    const themesDropdown = nullSafeQuerySelector(document,'#themesMenuButton');
    const organisationsDropdown = nullSafeQuerySelector(document, '#organisationsMenuButton');
    const searchOverlayDropdownDesktopBtn = nullSafeQuerySelector(document, '#searchButtonNavDesktop');
    const closeThemesDropdownBtn = nullSafeQuerySelector(document, '.btn-close-main-nav-themes');
    const closeOrganisationsDropdownBtn = nullSafeQuerySelector(document, '.btn-close-main-nav-organisations');
    const closeSearchOverlayDropdownDesktopBtn = nullSafeQuerySelector(document, '.btn-close-main-nav-search-overlay-desktop');
    const themeLinksDropdowns = nullSafeQuerySelectorAll(document,'.btn-themes-menu-theme');
    const themesMenuDropdown = nullSafeQuerySelector(document, '.themes-menu-dropdown');
    const searchOverlayDropdownDesktop = nullSafeQuerySelector(document, '.search-menu-dropdown-desktop');
    const organisationsMenuDropdown = nullSafeQuerySelector(document, '.organisations-menu-dropdown');
    const cardContainer = nullSafeQuerySelectorAll(document,'.card-container')[0];
    const themesDropdownReference = nullSafeQuerySelector(document, '.ref-for-dropdown');

    nullSafeForEach(themeLinksDropdowns,function (themeLinksDropdown) {

        createDropdown(themesDropdownReference, themeLinksDropdown);

        nullSafeAddEventListener(themeLinksDropdown,'show.bs.dropdown', function () {
            addBackground();
        })

        nullSafeAddEventListener(themeLinksDropdown,'shown.bs.dropdown', function () {
            dropdownPositioning(this);
        })

        nullSafeAddEventListener(themeLinksDropdown,'hide.bs.dropdown', function () {
            removeBackground();
        })
    });

    nullSafeAddEventListener(closeThemesDropdownBtn,'click', function () {
        closeThemesDropdown();
    });

    nullSafeAddEventListener(closeOrganisationsDropdownBtn,'click', function () {
        closeOrganisationsDropdown();
        nullSafeClassList(themesDropdown).remove("not-active");
    });

    nullSafeAddEventListener(closeSearchOverlayDropdownDesktopBtn,'click', function () {
        closeSearchOverlayDesktopDropdown();
    });

    nullSafeAddEventListener(organisationsDropdown,'show.bs.dropdown', function () {
        closeSearchOverlayDesktopDropdown();
        closeThemesDropdown();
        nullSafeClassList(themesDropdown).add("not-active");
    });

    nullSafeAddEventListener(organisationsDropdown,'shown.bs.dropdown', function () {
        document.addEventListener('click', listenForOutsideClickOfOrgaNav);
    });

    nullSafeAddEventListener(organisationsDropdown,'hidden.bs.dropdown', function () {
        closeThemesDropdown();
        nullSafeClassList(themesDropdown).remove("not-active");
    });

    nullSafeAddEventListener(themesDropdown,'show.bs.dropdown', function () {
        closeSearchOverlayDesktopDropdown();
        closeOrganisationsDropdown();
        nullSafeClassList(themesDropdown).remove("not-active");
    });

    nullSafeAddEventListener(themesDropdown,'shown.bs.dropdown', function () {
        document.addEventListener('click', listenForOutsideClickOfThemesNav);
    });

    nullSafeAddEventListener(searchOverlayDropdownDesktopBtn,'show.bs.dropdown', function () {
        closeThemesDropdown();
        closeOrganisationsDropdown();
        nullSafeClassList(organisationsDropdown).add("not-active");
        nullSafeClassList(themesDropdown).add("not-active");
    });

    nullSafeAddEventListener(searchOverlayDropdownDesktopBtn,'shown.bs.dropdown', function () {
        nullSafeQuerySelector(searchOverlayDropdownDesktop,'input').focus();
        document.addEventListener('click', listenForOutsideClickOfSearchOverlay);
    });

    nullSafeAddEventListener(searchOverlayDropdownDesktopBtn,'hidden.bs.dropdown', function () {
        nullSafeClassList(organisationsDropdown).remove("not-active");
        nullSafeClassList(themesDropdown).remove("not-active");
    })

    function listenForOutsideClickOfSearchOverlay (clickTarget) {
        if (!searchOverlayDropdownDesktop.contains(clickTarget.target) && ![...clickTarget.target.classList].includes("btn")) {
            closeSearchOverlayDesktopDropdown();
        }
    }

    function listenForOutsideClickOfThemesNav (clickTarget) {
        if (!themesMenuDropdown.contains(clickTarget.target) && ![...clickTarget.target.classList].includes("btn")) {
            closeThemesDropdown();
        }
    }

    function listenForOutsideClickOfOrgaNav (clickTarget) {
        if (!organisationsMenuDropdown.contains(clickTarget.target) && ![...clickTarget.target.classList].includes("btn")) {
            closeOrganisationsDropdown();
        }
    }

    function removeBackground() {
        nullSafeClassList(themesMenuDropdown).remove("gradient-bg-light-from-right");
        nullSafeClassList(cardContainer).remove("bg-light");
    }

    function addBackground() {
        nullSafeClassList(themesMenuDropdown).add("gradient-bg-light-from-right");
        nullSafeClassList(cardContainer).add("bg-light");
    }

    function dropdownPositioning(dropdown) {
        const positionThemesLinkButton = themeLinksDropdowns[0].getBoundingClientRect();
        const desiredWidthOfLinksDropdown = (themesMenuDropdown.offsetWidth - themeLinksDropdowns.offsetWidth - ((themesMenuDropdown.offsetWidth - nullSafeQuerySelector(themesMenuDropdown, '.container').offsetWidth)/2) - 2);
        // set width of dropdown to width of right side of the menu (Dropdown width - Links Width - ( (Dropdown width - container width) / 2)
        dropdown.nextElementSibling.style.width = desiredWidthOfLinksDropdown + "px";
        // set padding-right to mimic bootstrap container
        dropdown.nextElementSibling.style.paddingRight = ((themesMenuDropdown.offsetWidth - nullSafeQuerySelector(themesMenuDropdown,'.container').offsetWidth)/2) + "px";
        // set height s it is full height of right side
        dropdown.nextElementSibling.style.height = (themesMenuDropdown.offsetHeight-2) + "px";
    }

    function closeThemesDropdown() {
        // Close/toggle status of themesDropdown on click
        nullSafeClassList(themesDropdown).remove('show');
        themesDropdown.setAttribute('aria-expanded', 'false');
        // close main dropdown
        nullSafeClassList(themesMenuDropdown).remove('show');
        removeBackground();
        // remove Eventlistner for click outside of modal from body
        document.removeEventListener('click', listenForOutsideClickOfThemesNav);
    }

    function closeOrganisationsDropdown() {
        // Close/toggle status of themesDropdown on click
        nullSafeClassList(organisationsDropdown).remove('show');
        organisationsDropdown.setAttribute('aria-expanded', 'false');
        // close main dropdown
        nullSafeClassList(organisationsMenuDropdown).remove('show');
        nullSafeClassList(themesDropdown).remove("not-active");
        // remove Eventlistner for click outside of modal from body
        document.removeEventListener('click', listenForOutsideClickOfOrgaNav);
    }

    function closeSearchOverlayDesktopDropdown() {
        searchOverlayDropdownDesktopBtn.setAttribute('aria-expanded', 'false');
        nullSafeClassList(searchOverlayDropdownDesktopBtn).remove('show');
        nullSafeClassList(searchOverlayDropdownDesktop).remove('show');
        nullSafeClassList(themesDropdown).remove("not-active");
        nullSafeClassList(organisationsDropdown).remove("not-active");
        document.removeEventListener('click', listenForOutsideClickOfSearchOverlay);
    }

    function createDropdown(ref, el){
        new Dropdown(el, {
            reference: ref,
            popperConfig: function (defaultBsPopperConfig) {
                return {
                    ...defaultBsPopperConfig,
                    strategy: "fixed",
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                mainAxis: false, // true by default
                                altAxis: false,
                            },
                        }
                    ],
                }
            }
        })
    }

    function nullSafeAddEventListener(element, type, callback) {
        if (element) {
            element.addEventListener(type, callback);
        }
    }

    function nullSafeQuerySelectorAll(element, selector) {
        if (element) {
            return element.querySelectorAll(selector);
        }
    }

    function nullSafeQuerySelector(element, selector) {
        if (element) {
            return element.querySelector(selector);
        }
    }

    function nullSafeClassList(element) {
        if (element) {
            return element.classList;
        } else {
            return new DOMTokenList();
        }
    }

    function nullSafeForEach(element, lambda) {
        if (element) {
            element.forEach(lambda)
        }
    }
});