import Swiper, { Navigation, Pagination, A11y, Keyboard, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, A11y, Keyboard, Autoplay]);

document.addEventListener('DOMContentLoaded', e => {
    if (document.getElementsByClassName('dossier-slider').length > 0) {
        const dossierList = document.querySelector('.row.dossier-list');
        const prevSlideText = dossierList.dataset.prevSlide;
        const nextSlideText = dossierList.dataset.nextSlide;
        const firstSlideText = dossierList.dataset.firstSlide;
        const lastSlideText = dossierList.dataset.lastSlide;
        const paginationBulletText = dossierList.dataset.paginationBullet;

        new Swiper('.dossier-swiper', {
            slidesPerView: 1,
            spaceBetween: 30,
            autoHeight: false,
            simulateTouch: true,
            slidesOffsetBefore: 5,
            slidesOffsetAfter: 5,
            speed: 400,

            autoplay: {
                delay: 3000,
            },

            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },

            // Breakpoints for >=
            breakpoints: {
                720: {
                    slidesPerView: 2.0,
                    spaceBetween: 24,
                    autoplay: false
                },
                1025: {
                    slidesPerView: 2.4,
                    spaceBetween: 32,
                    autoplay: false
                },
            },

            navigation: {
                nextEl: '.dossier-swiper-button-next',
                prevEl: '.dossier-swiper-button-prev',
                disabledClass: 'btn-disabled',
            },

            pagination: {
                el: '.dossier-swiper-pagination',
                type: 'bullets',
                bulletActiveClass: 'dossier-swiper-pagination-bullet-active',
                clickable: true,
            },

            a11y: {
                enabled: true,
                prevSlideMessage: prevSlideText,
                nextSlideMessage: nextSlideText,
                lastSlideMessage: lastSlideText,
                firstSlideMessage: firstSlideText,
                paginationBulletMessage: paginationBulletText + ' {{index}}',
                slideLabelMessage: '{{index}} / {{slidesLength}}',
            },
        });
    }
});

