let didScroll;
let lastScrollTop = 0;
const delta = 3;
// check if user scrolled up more than once because otherwise chrome logs an scroll up event
// when the in page nav gets sticky and falsly shows the header
let scrollUpTimes = 0;
const navbarHeight = $('header').outerHeight();
let inPageNavArea = document.querySelector('.inPageNavigationArea');
let inPageNav = document.querySelector('#in-page-navigation');

const navigationSection = document.getElementById('navigation-section');
const navigationSectionJobs = document.querySelector('.jobs-navigation-wrapper');

function scrollToHash() {
    var hash = window.location.hash;
    
    if (hash) {
        var target = document.querySelector(hash);        
        if (target) {

            // If item is in accordion -> expand it
            if (target.className == "accordion-item") {
                const accordionSubItem = target.querySelector(".accordion-collapse");
                accordionSubItem.classList.add("show");
            }
            target.scrollIntoView({ behavior: 'smooth' });
        }
    }
}

// If page is loaded or hash is changed -> Scroll to hash
window.addEventListener('DOMContentLoaded', scrollToHash);
window.addEventListener("hashchange", scrollToHash);

$(window).scroll(function (event) {
    didScroll = true;
});

setInterval(function () {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

setDragListenersForInPageNav(navigationSection);
setDragListenersForInPageNav(navigationSectionJobs);

function setDragListenersForInPageNav (navigationSection) {
    if (navigationSection) {
        navigationSection.ondragstart = function() {
            return false;
        };

        navigationSection.ondrop = function() {
            return false;
        };
    }
}

function hasScrolled() {
    let currentScrollTop = $(this).scrollTop();

    if ($(window).width() > 719)
        return;

    if (Math.abs(lastScrollTop - currentScrollTop) <= delta)
        return;

    if (currentScrollTop > lastScrollTop && currentScrollTop > navbarHeight) {
        scrollUpTimes = 0;
        $('header').addClass('header-up');
        if ($('header').offset().top > 0) {
            $('header').addClass('border-bottom');
        }
        if(inPageNav){
            inPageNavArea.style.top = '0';
        }
    } else {
        if (currentScrollTop + $(window).height() < $(document).height()) {
            scrollUpTimes++
            if(scrollUpTimes > 2){
                $('header').removeClass('header-up');
                if ($('header').offset().top < 1) {
                    $('header').removeClass('border-bottom');
                }
                if(inPageNav && !window.matchMedia('(min-width: 720px)').matches){
                    if (inPageNavArea.style.position === 'sticky') {
                        inPageNavArea.style.top = '80px';
                    }
                }
            }
        }
    }
    lastScrollTop = currentScrollTop;
}

handleNavigationOverflow(true);
window.addEventListener('resize', (e) => handleNavigationOverflow(false));

function handleNavigationOverflow (initial) {

    const headerContainerDiv = document.querySelector("header .container div");
    if (!headerContainerDiv) {
        return;
    }
    const children = headerContainerDiv.children;

    let sum = 0;
    let overflow = false;

    for (child of children) {
        var styles = window.getComputedStyle(child);
        if (!child.classList.contains('dropdown-menu')) {
            sum += child.offsetWidth + parseFloat(styles.marginLeft) + parseFloat(styles.marginRight);
        }
    }
    if (sum > headerContainerDiv.offsetWidth) {
        overflow = true;
    }

    const mobileNav = document.querySelector('.microsite .mobile-nav');
    const desktopNav = document.querySelector('.microsite .desktop-nav');
    const logo = document.querySelector('.microsite .logo');

    if (mobileNav && desktopNav) {
        mobileNav.style.removeProperty('display');
        desktopNav.style.removeProperty('display');
        desktopNav.style.removeProperty('invisible');

        if (overflow) {
            desktopNav.classList.remove('d-md-flex');
            mobileNav.classList.remove('d-md-none');
            desktopNav.classList.add('invisible');
        } else if (initial) {
            desktopNav.classList.add('d-md-flex');
            mobileNav.classList.add('d-md-none');
            desktopNav.classList.remove('invisible');
        }
    }

    if (logo) {
        logo.classList.remove('invisible')
    }
}

function navHasOverflowingContent() {

}
