document.addEventListener('DOMContentLoaded', e => {
    let mailLinks = document.querySelectorAll('.mail-link');

    mailLinks.forEach(function (mailLink) {
        mailLink.addEventListener("click", function () {
            window.location.href = 'mailto:' + mailLink.dataset.name + '@' + mailLink.dataset.domain + '.' + mailLink.dataset.tld;
            return false;
        });
    });
})
